.Store {
  .menuContainer {
    margin: 0 auto;
    max-width: 1920px;

    @media screen and (min-width: 1234px) {
      padding: 0 60px;
    }
  
    @media screen and (min-width: 1366px) {
      padding: 0 80px;
    }
  }
}