/* Import fonts */
@font-face {
  font-family: 'Louize Display Medium';
  src: url('../assets/fonts/Louize Display - Medium-205TF.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro Regular';
  src: url('../assets/fonts/Sofia Pro Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Sofia Pro Light';
  src: url('../assets/fonts/Sofia Pro Light.otf') format('truetype');
}

@font-face {
  font-family: 'Work Sans Regular';
  src: url('../assets/fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans Medium';
  src: url('../assets/fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans Medium Italic';
  src: url('../assets/fonts/WorkSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Simplon Mono Regular';
  src: url('../assets/fonts/SimplonMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Simplon Mono Italic';
  src: url('../assets/fonts/SimplonMono-RegularItalic.ttf') format('truetype');
}

@mixin LouizeDisplayMedium {
  font-family: 'Louize Display Medium', serif;
}

@mixin SofiaProRegular {
  font-family: 'Sofia Pro Regular', sans-serif;
}

@mixin SofiaProLight {
  font-family: 'Sofia Pro Light', sans-serif;
}

@mixin WorkSansRegular {
  font-family: 'Work Sans Regular', sans-serif;
}

@mixin WorkSansMedium {
  font-family: 'Work Sans Medium', sans-serif;
}

@mixin WorkSansMediumItalic {
  font-family: 'Work Sans Medium Italic', sans-serif;
}

@mixin SimplonMonoRegular {
  font-family: 'Simplon Mono Regular', serif;
}

/* Import global stylesheets */
@import './colors.scss';
@import './resets.scss';
@import './master.scss';
@import './v2.scss';

/* Import component stylesheets */
@import '../nav/nav.scss';
@import '../views/landing/landing.scss';
@import '../views/about/about.scss';
@import '../views/legal/legal.scss';
@import '../views/store/store.scss';
@import '../views/franchise/franchise.scss';
@import '../views/quiz/quiz.scss';
@import '../views/rhodeisland/rhodeisland.scss';
@import './storeInfo.scss';