.image-container {
    margin-top: 15rem;
    text-align: center; /* Center the child elements horizontally */
}

.logo{
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.audio-player{
    padding-top: 2rem;
    padding-bottom: 10rem;
}

