.StoreInfo {
  position: relative;

  /* Banner */

  .BannerBackground {
    width: calc(100vw - (100vw - 100%)); // Compensate for scrollbar
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    
    @media screen and (min-width: 1366px) {
      max-height: 900px;
    }
  }

  .Banner {
    position: relative;
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    background-color: rgba(0, 0, 0, 0.15);

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
      height: calc(100vh - 60px); // compensating for header bar height
      min-height: calc(600px - 60px); // compensating for header bar height
      max-height: calc(768px - 60px); // compensating for header bar height
      padding: 100px 0;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h1 {
          @include WorkSansMedium;
          color: $v2GreenPale;
          padding: 20px;
        }
      }
    }

    @media screen and (min-width: 900px) {
      .contentWrapper {
        height: calc(100vh - 100px); // compensating for header bar height
        min-height: calc(600px - 100px); // compensating for header bar height
        max-height: calc(768px - 100px); // compensating for header bar height
        padding: 0px 60px;
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        .content {
          border-right: 1px solid $v2GreenPale;
          border-left: 1px solid $v2GreenPale;
        }
      }

    }

    @media screen and (min-width: 1366px) {
      max-height: 900px;

      .contentWrapper {
        padding: 0px 80px;
        max-height: calc(900px - 100px); // compensating for header bar height

        .content {
          h1 {
            padding: 60px;
          }
        }
      }
    }
  }

  .Details {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      font-size: .95rem;
      line-height: 1.5rem;
      letter-spacing: 0.003rem;

      .dispensaryType {
        border-bottom: 1px solid $v2GreenDark;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        .title {
          @include WorkSansMedium;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
        }
      }

      .addressContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid $v2GreenDark;
        padding: 30px 20px;
      }

      .address, .contact, .hours {
        display: flex;
        flex-direction: column;

        .title {
          @include WorkSansMedium;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          margin-bottom: 15px;
        }

        .description {
          display: flex;
          flex-direction: column;
        }
      }

      .address {
        margin-right: 20px;
      }

      .getDirections {
        margin-top: 30px;
      }

      .contact {
        border-bottom: 1px solid $v2GreenDark;
        padding: 30px 20px;

        .item {
          .label {
            margin-right: 10px;
          }
        }
      }

      .hoursContainer {
        padding: 30px 20px;
      }

      .hours {
        .item {
          .label {
            text-transform: uppercase;
            margin-right: 10px;
          }
        }
      }

      .hours:not(:first-child) {
        margin-top: 20px;
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        .dispensaryType {
          padding: 40px 20px;
        }

        .locationInfo {
          display: flex;
          justify-content: space-between;

          .addressAndContact, .hoursContainer {
            width: 50%;
          }
        }

        .dispensaryType, .address, .contact, .hours {
          .title {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }
        }

        .getDirections {
          margin-right: 10px;
        }

        .contact {
          border-bottom: none;
        }

        .hoursContainer {
          padding: 30px 20px 30px 30px;
          display: flex;
          justify-content: space-between;
          border-left: 1px solid $v2GreenDark;
        }

        .hours {
          flex: 1;

          .title {
            max-width: 170px;
            margin-bottom: 30px;
          }
        }

        .hours:not(:first-child) {
          margin-top: 0;
          margin-left: 20px;
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .content {
        font-size: 1.2rem;
        line-height: 1.75rem;

        .dispensaryType {
          padding: 60px 20px;
        }

        .dispensaryType, .address, .contact, .hours {
          .title {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
        
        .hours {
          .title {
            max-width: 210px;
          }
        }
      }
    }
  }

  .Menus {
    border-bottom: 1px solid $v2GreenPale;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      display: flex;
      flex-direction: column;

      .imageContainer {
        img {
          width: 100%;
          height: auto;
        }
      }

      .menusContainer {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
          font-size: 1.75rem;
          line-height: 3rem;
          letter-spacing: 0.05rem;
          margin-bottom: 20px;
        }

        .subHeading {
          @include WorkSansMedium;
          font-size: .95rem;
          line-height: 1.5rem;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          color: $v2GreenPale;
          // Overrides
          text-align: left;
          margin-bottom: 30px;
        }

        .menuButtons {
          display: flex;
          align-items: center;

          .buttonContainer:not(:first-child) {
            margin-left: 30px;
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        flex-direction: row-reverse;
        justify-content: space-between;

        .imageContainer, .menusContainer {
          width: 50%;
        }

        .imageContainer {
          border-left: 1px solid $v2GreenPale;
        }

        .menusContainer {
          .heading {
            font-size: 2rem;
            letter-spacing: 0.075rem;
            max-width: 500px;
          }

          .subHeading {
            font-size: 1.2rem;
            line-height: 1.75rem;
            margin-bottom: 60px;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;

        .menusContainer {
          .heading {
            margin-bottom: 30px;
          }

          .subHeading {
            margin-bottom: 80px;
          }

          .menuButtons {
            .buttonContainer:not(:first-child) {
              margin-left: 45px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .content {
        .menusContainer {
          .heading {
            font-size: 2.75rem;
            line-height: 4rem;
            letter-spacing: 0.2rem;
            max-width: 700px;
          }

          .subHeading {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
