/* Standard Styles */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}

a,
button {
  cursor: pointer;
}

/* Structural Styles */

.section {
  @media screen and (min-width: 1366px) {
    width: 1234px;
    margin: 0 auto;
  }
}

/* Global Styles */

html,
body,
#root,
.App {
  height: 100%; /* Force full height for root App div */
}

body {
  @include SofiaProLight;
  color: $brownDarker;
  background-color: $whiteBackground;
  font-size: 13px;
  line-height: 20px;
  // @media screen and (min-width: 1366px) {
  //   font-size: 20px;
  //   line-height: 27px;
  // }
}

/* Allow pointer events to occur on Ant Modal */
.ant-modal {
  pointer-events: all;
}

/* Headings */

h1 {
  @include LouizeDisplayMedium;
  color: $brownPaler;
  font-weight: normal;
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  @media screen and (min-width: 768px) {
    font-size: 35px;
  }
  @media screen and (min-width: 1366px) {
    font-size: 50px;
    line-height: 58px;
    text-align: left;
  }
}

h2 {
  @include SofiaProRegular;
  color: $brownDarker;
  font-weight: normal;
  text-decoration: underline;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  padding: 15px;
  @media screen and (min-width: 768px) {
    font-size: 15px;
    padding: 20px;
  }
  @media screen and (min-width: 1366px) {
    font-size: 22px;
    line-height: 27px;
    padding: 65px 0;
  }
}

h3 {
  @include LouizeDisplayMedium;
  color: $brownDarker;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media screen and (min-width: 1366px) {
    font-size: 50px;
    line-height: 60px;
  }
}

.subHeading {
  @include LouizeDisplayMedium;
  color: $brownDarker;
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media screen and (min-width: 1366px) {
    font-size: 50px;
    line-height: 58px;
  }
}

/* Buttons */

.navButton {
  @media screen and (min-width: 1366px) {
    @include SofiaProRegular;
    color: $brownDarker;
    font-size: 20px;
    line-height: 27px;
    padding: 12px 22px;
    border-radius: 50%;
    border: solid transparent 1px; // Invisible border
    background: transparent;
  }
}

.actionButton {
  @include SofiaProRegular;
  color: $brownPaler;
  width: 125px;
  height: 42px;
  font-size: 13px;
  line-height: 27px;
  border: solid $brownDark 1px;
  border-radius: 20px;
  background: $brownDark;
  @media screen and (min-width: 1366px) {
    width: 208px;
    height: 66px;
    font-size: 20px;
    line-height: 27px;
    border-radius: 32px;
  }
}

/* Age Gate */

.gateWrapper {
  height: 100%; // Expand to height of modal content
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $v2GreenPale;
  .gateContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    header {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      .image {
        img {
          height: 24px;
          width: auto;
        }
      }
      @media screen and (min-width: 768px) {
        margin-bottom: 60px;
        .image {
          img {
            height: 30px;
          }
        }
      }
      @media screen and (min-width: 1366px) {
        margin-bottom: 90px;
        .image {
          img {
            height: 45px;
          }
        }
      }
    }
    h1 {
      @include SimplonMonoRegular;
      font-weight: normal;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      margin-bottom: 20px;
      color: $greenDarkAlt;
      text-transform: none; // override
      letter-spacing: 0; // override
      max-width: 300px;
      @media screen and (min-width: 768px) {
        font-size: 35px;
        line-height: 45px;
        max-width: 500px;
      }
      @media screen and (min-width: 1366px) {
        font-size: 45px;
        line-height: 60px;
        max-width: 600px;
        margin-bottom: 45px;
      }
    }
    h1.thinner1 {
      // No medical card message
      max-width: 260px;
      @media screen and (min-width: 768px) {
        max-width: 500px;
      }
      @media screen and (min-width: 1366px) {
        max-width: 700px;
      }
    }
    h1.thinner2 {
      // Medical card question
      max-width: 360px;
      @media screen and (min-width: 768px) {
        max-width: 440px;
      }
      @media screen and (min-width: 1366px) {
        max-width: 550px;
      }
    }
    // Delivery method question
    h1.thinner3 {
      max-width: 260px;
      @media screen and (min-width: 768px) {
        max-width: 400px;
      }
      @media screen and (min-width: 1366px) {
        max-width: 580px;
      }
    }
    p {
      @include SimplonMonoRegular;
      max-width: 260px;
      text-align: center;
      color: $v2GreenDark;
      font-size: 14px;
      line-height: 27px;
      margin-bottom: 20px;
      text-transform: uppercase;
      @media screen and (min-width: 1366px) {
        font-size: 16px;
        max-width: 100%;
        margin-bottom: 45px;
      }
    }
    .completionMessage {
      max-width: 300px;
      @media screen and (min-width: 768px) {
        max-width: 360px;
      }
      @media screen and (min-width: 1366px) {
        max-width: 450px;
      }
    }
    a {
      text-decoration: underline;
    }
    .gateButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        @include SimplonMonoRegular;
        height: 44px;
        width: 145px;
        border-radius: 22px;
        padding: 0 22px;
        text-transform: uppercase;
        line-height: 1.25rem;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        border: 1px solid;
      }
      .gateButtonNo {
        border: solid $v2GreenDark 1px;
        background-color: transparent;
        color: $v2GreenDark;
        margin-bottom: 20px;
      }
      .gateButtonYes {
        border: solid $v2GreenDark 1px;
        background-color: $v2GreenDark;
        color: $v2GreenPale;
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
        .gateButtonNo {
          margin-right: 30px;
          margin-bottom: 0;
        }
      }
    }
    .screeningButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      button {
        @include SimplonMonoRegular;
        height: 44px;
        width: 110px;
        border-radius: 22px;
        padding: 0 22px;
        text-transform: uppercase;
        line-height: 1.25rem;
        font-size: 0.9rem;
        letter-spacing: 0.05rem;
        border: 1px solid;
      }
      button.wide {
        width: 140px;
      }
      .screeningButtonNo {
        border: solid $v2GreenDark 1px;
        background-color: transparent;
        color: $v2GreenDark;
      }
      .screeningButtonYes {
        border: solid $v2GreenDark 1px;
        background-color: $v2GreenDark;
        color: $v2GreenPale;
        margin-top: 20px;
      }
      .screeningButtonYes.noMargin {
        margin-top: 0;
        margin-left: 0;
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
        padding-top: 45px;
        .screeningButtonYes {
          margin-left: 30px;
          margin-top: 0;
        }
      }
    }
  }
}

/* Header */

header {
  width: 100%;
  // @media screen and (min-width: 1366px) {
  //   width: 1234px;
  //   margin: 0 auto;
  //   position: relative;
  // }
  .landing {
    height: 490px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: right / contain no-repeat $brownDark;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .plantImageWrapper {
        height: 100px;
        margin-bottom: 30px;
        img {
          height: 100%;
          width: auto;
        }
      }
      .archPlant {
        height: 40px;
        width: auto;
      }
      h1 {
        width: 261px;
        margin-bottom: 30px;
        .selfCare {
          white-space: nowrap;
        }
        .circled {
          margin: 0 8px;
        }
      }
      p {
        @include SofiaProRegular;
        font-size: 16px;
        text-align: center;
        color: $whiteText;
        width: 261px;
        margin-bottom: 30px;
      }
      button {
        background-color: $brownPrimary;
        color: $whiteText;
      }
      .underline {
        display: none;
      }
    }
    @media screen and (min-width: 1024px) {
      background: right / contain no-repeat $brownDark
        url("../assets/images/landing-banner.png");
      justify-content: flex-start;
      padding-left: 10%;
      .content {
        width: 30%;
        align-items: flex-start;
        .plantImageWrapper {
          display: none;
        }
        h1 {
          width: 100%;
          text-align: left;
        }
        p {
          width: 100%;
          font-size: 18px;
          margin-bottom: 45px;
          text-align: left;
        }
      }
    }
    @media screen and (min-width: 1366px) {
      height: 587px;
      background: right / contain no-repeat $brownDark
        url("../assets/images/landing-banner.png");
      padding-left: 75px;
      .content {
        h1 {
          width: 400px;
        }
        p {
          font-size: 20px;
          width: 300px;
        }
      }
    }
  }
  .about {
    height: 230px;
    width: 100%;
    background: center / cover no-repeat
      url("../assets/images/about-banner-mobile-02.png");
    @media screen and (min-width: 768px) {
      height: 450px;
    }
    @media screen and (min-width: 1366px) {
      height: 587px;
    }
  }
  .spinningText {
    display: none;
    @media screen and (min-width: 1366px) {
      display: block;
      position: fixed;
      top: calc(
        100px + 587px - 57px
      ); // Height of desktop nav bar plus height of banner image less half of the spinning text image
      z-index: 99;
      width: 115px;
      height: 111px;
      img {
        width: 100%;
      }
    }
  }
}

/* Footer */

footer {
  color: $whiteText;
  background-color: $brownDarker;
  padding-bottom: 30px;
  @media screen and (min-width: 768px) {
    padding-bottom: 40px;
  }
  @media screen and (min-width: 1366px) {
    padding-bottom: 65px;
  }
  .banner {
    width: 261px;
    height: 55px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid $whiteText 1px;
    .image {
      height: 26px;
      img {
        height: 100%;
      }
    }
    @media screen and (min-width: 768px) {
      width: 621px;
      height: 80px;
    }
    @media screen and (min-width: 1366px) {
      width: 1031px;
      height: 133px;
      .image {
        height: 38px;
      }
    }
  }
  .panels {
    margin: 0 auto;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 261px;
    @media screen and (min-width: 768px) {
      width: 621px;
      padding-top: 30px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    @media screen and (min-width: 1366px) {
      width: 1031px;
      padding: 50px 40px 0 40px;
    }
    .panel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        width: 180px;
        align-items: flex-start;
        margin-bottom: 0;
      }
      @media screen and (min-width: 1366px) {
        width: 265px;
      }
      .title {
        @include SofiaProRegular;
        text-decoration: underline;
        margin-bottom: 20px;
        font-size: 15px;
        @media screen and (min-width: 1366px) {
          margin-bottom: 30px;
          font-size: 22px;
        }
      }
      .text,
      .address {
        @include SofiaProLight;
        text-align: center;
        margin-bottom: 15px;
        @media screen and (min-width: 768px) {
          text-align: left;
        }
        @media screen and (min-width: 1366px) {
          margin-bottom: 30px;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
      }
      .button {
        .text {
          @include SofiaProRegular;
          margin-right: 10px;
        }
      }
      .email {
        margin-left: 5px; // Align w/social icons
        @media screen and (min-width: 768px) {
          padding-top: 20px;
          margin-left: 10px; // Align w/social icons
          .icon {
            font-size: 35px;
            padding: 0 10px;
          }
        }
      }
      .social {
        margin-bottom: 20px;
        .icon {
          font-size: 25px;
          padding: 0 5px;
        }
        @media screen and (min-width: 768px) {
          padding-top: 20px;
          .icon {
            font-size: 35px;
            padding: 0 10px;
          }
        }
        @media screen and (min-width: 1366px) {
          padding-top: 30px;
          margin-bottom: 40px;
          .icon {
            font-size: 45px;
          }
        }
      }
    }
  }
}

/* Custom tooltips */
.infoTooltip {
  @include WorkSansRegular;
  font-size: 14px;
  line-height: 18px;
  .ant-tooltip-inner {
    border-radius: 8px;
    padding: 20px;
  }
}

/* Circle mask animation */
/* Takes advantage of clip-path property to mask an element's contents */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path */

.circled {
  position: relative;
  span {
    display: none;
    @media screen and (min-width: 1366px) {
      display: block;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: circleDraw linear 2s;
    }
  }
}

@keyframes circleDraw {
  0% {
    clip-path: polygon(50% 0, 50% 50%); // Full mask coverage
  }
  4% {
    clip-path: polygon(50% 0, 50% 50%, 67% 0);
  }
  8% {
    clip-path: polygon(50% 0, 50% 50%, 83% 0);
  }
  13% {
    clip-path: polygon(50% 0, 50% 50%, 100% 0);
  }
  17% {
    clip-path: polygon(50% 0, 50% 50%, 100% 17%, 100% 0);
  }
  21% {
    clip-path: polygon(50% 0, 50% 50%, 100% 33%, 100% 0);
  }
  25% {
    clip-path: polygon(50% 0, 50% 50%, 100% 50%, 100% 0);
  }
  29% {
    clip-path: polygon(50% 0, 50% 50%, 100% 67%, 100% 0);
  }
  33% {
    clip-path: polygon(50% 0, 50% 50%, 100% 83%, 100% 0);
  }
  38% {
    clip-path: polygon(50% 0, 50% 50%, 100% 100%, 100% 0);
  }
  42% {
    clip-path: polygon(50% 0, 50% 50%, 83% 100%, 100% 100%, 100% 0);
  }
  46% {
    clip-path: polygon(50% 0, 50% 50%, 67% 100%, 100% 100%, 100% 0);
  }
  50% {
    clip-path: polygon(50% 0, 50% 50%, 50% 100%, 100% 100%, 100% 0);
  }
  54% {
    clip-path: polygon(50% 0, 50% 50%, 33% 100%, 100% 100%, 100% 0);
  }
  58% {
    clip-path: polygon(50% 0, 50% 50%, 17% 100%, 100% 100%, 100% 0);
  }
  63% {
    clip-path: polygon(50% 0, 50% 50%, 0% 100%, 100% 100%, 100% 0);
  }
  67% {
    clip-path: polygon(50% 0, 50% 50%, 0% 83%, 0% 100%, 100% 100%, 100% 0);
  }
  71% {
    clip-path: polygon(50% 0, 50% 50%, 0% 67%, 0% 100%, 100% 100%, 100% 0);
  }
  75% {
    clip-path: polygon(50% 0, 50% 50%, 0% 50%, 0% 100%, 100% 100%, 100% 0);
  }
  79% {
    clip-path: polygon(50% 0, 50% 50%, 0% 33%, 0% 100%, 100% 100%, 100% 0);
  }
  83% {
    clip-path: polygon(50% 0, 50% 50%, 0% 17%, 0% 100%, 100% 100%, 100% 0);
  }
  88% {
    clip-path: polygon(50% 0, 50% 50%, 0% 0%, 0% 100%, 100% 100%, 100% 0);
  }
  92% {
    clip-path: polygon(
      50% 0,
      50% 50%,
      17% 0%,
      0% 0%,
      0% 100%,
      100% 100%,
      100% 0
    );
  }
  96% {
    clip-path: polygon(
      50% 0,
      50% 50%,
      33% 0%,
      0% 0%,
      0% 100%,
      100% 100%,
      100% 0
    );
  }
  100% {
    clip-path: polygon(
      50% 0,
      50% 50%,
      50% 0%,
      0% 0%,
      0% 100%,
      100% 100%,
      100% 0
    ); // No mask coverage
  }
}

/* Custom logo spinner */

.logoSpinner {
  animation: logoSpinner 2.5s linear infinite;
}

@keyframes logoSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}