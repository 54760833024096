.Franchise {
  position: relative;

  /* Banner */

  .BannerBackground {
    width: calc(100vw - (100vw - 100%)); // Compensate for scrollbar
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../assets/images/franchise-banner-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    
    @media screen and (min-width: 1366px) {
      max-height: 900px;
    }
  }

  .Banner {
    position: relative;
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    background-color: rgba(0, 0, 0, 0.15);

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
      height: calc(100vh - 60px); // compensating for header bar height
      min-height: calc(600px - 60px); // compensating for header bar height
      max-height: calc(768px - 60px); // compensating for header bar height
      padding: 100px 0;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        h1 {
          @include WorkSansMedium;
          color: $v2GreenPale;
          margin-bottom: 60px;

          .desktopOnly {
            display: none;
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      .contentWrapper {
        height: calc(100vh - 100px); // compensating for header bar height
        min-height: calc(600px - 100px); // compensating for header bar height
        max-height: calc(768px - 100px); // compensating for header bar height
        padding: 0px 60px;

        .content {
          h1 {
            font-size: 2rem;
            line-height: 3.25rem;
            letter-spacing: 0.3rem;
            color: $v2GreenDark;
            background-color: $v2GreenPale;
            width: 390px;
            margin: 0 auto;
            padding: 30px;

            .desktopOnly {
              display: inline;
            }
          }

          .infoButtonContainer {
            display: none;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        .content {
          border-right: 1px solid $v2GreenPale;
          border-left: 1px solid $v2GreenPale;

          h1 {
            font-size: 2.75rem;
            line-height: 4rem;
            letter-spacing: 0.4rem;
            width: 500px;
            padding: 45px;
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      max-height: 900px;

      .contentWrapper {
        padding: 0px 80px;
        max-height: calc(900px - 100px); // compensating for header bar height

        .content {
          h1 {
            font-size: 3.5rem;
            line-height: 4.75rem;
            letter-spacing: 0.4rem;
            width: 650px;
            padding: 60px;
          }
        }
      }
    }
  }

  /* Industry */

  .Industry {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper, .captionWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .itemsWrapper {
      border-bottom: 1px solid $v2GreenDark;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;

      .item {
        width: calc(50% - 20px);

        .title {
          @include WorkSansMedium;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
          font-size: 2.3rem;
          line-height: 3rem;
          margin-bottom: 10px;
        }

        .description {
          font-size: .95rem;
          line-height: 1.3rem;
          margin-bottom: 0;
        }
      }

      .item:nth-child(odd) {
        padding: 20px 20px 20px 0;
      }

      .item:nth-child(even) {
        padding: 20px 0 20px 20px;
        border-left: 1px solid $v2GreenDark;
      }

      .item:nth-child(-n + 2) {
        border-bottom: 1px solid $v2GreenDark;
      }
    }

    .imageContainer {
      display: flex;
      flex-direction: column-reverse;
      border-bottom: 1px solid $v2GreenDark;

      .image {
        width: 100%;
        height: calc(100vw * 0.714);
        max-height: 430px;
        background-image: url('../../assets/images/industry-plants.png');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
      }

      .caption {
        font-size: .95rem;
        line-height: 1.3rem;
        padding: 30px 20px;
        border-bottom: 1px solid $v2GreenDark;

        .title {
          @include WorkSansMedium;
          text-transform: uppercase;
          letter-spacing: 0.075rem;
        }

        .text {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (min-width: 900px) {

      .itemsWrapper {
        justify-content: space-between;
        flex-wrap: nowrap;
  
        .item {
          width: 25%;
  
          .title {
            font-size: 2.75rem;
            line-height: 4rem;
            margin-bottom: 15px;
          }
        }
  
        .item:nth-child(odd), .item:nth-child(even) {
          padding: 45px 20px 30px 20px;
          border-left: 1px solid $v2GreenDark;
        }
  
        .item:nth-child(-n + 2) {
          border-bottom: none;
        }

        .item:first-child {
          width: 25%;
          border-left: none;
        }
      }
  
      .imageContainer {
        display: block;
        position: relative;
        height: calc((100vw * 0.479));
        max-height: 919.67px;
  
        .image {
          position: absolute;
          height: calc(100vw * 0.479);
          max-height: 919.67px;
          margin-top: -1px; // Aligns borders
        }
  
        .caption {
          position: relative;
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          background-color: $v2GreenPale;
  
          .title {
            width: 25%;
            padding: 30px;
            font-size: 1.2rem;
            line-height: 1.75rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;
          }
  
          .text {
            width: 75%;
            border-left: 1px solid $v2GreenDark;
            padding: 30px 20px;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .itemsWrapper {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }

      .imageContainer {
        .captionWrapper {
          padding: 0 60px;
        }
        .caption {
          border-left: 1px solid $v2GreenDark;
          border-right: 1px solid $v2GreenDark;
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .itemsWrapper {
        .item {
          .title {
            letter-spacing: 0.3rem;
            font-size: 3.5rem;
            line-height: 4.5rem;
            margin-bottom: 20px;
          }
  
          .description {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }
        }

        .item:nth-child(odd), .item:nth-child(even) {
          padding-top: 60px;
        }
      }
  
      .imageContainer {
        .captionWrapper {
          padding: 0 80px;
        }
  
        .caption {  
          .title {
            font-size: 1.5rem;
            line-height: 2.25rem;
          }
  
          .text {
            font-size: 1.2rem;
            line-height: 1.75rem;
          }
        }
      }
    }
  }

  /* Our Story */

  .OurStory {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper, .imageContainerWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .imageContainer {
      padding: 0 20px;

      .caption {
        display: flex;
        justify-content: center;

        p {
          // This caption follows the larger blurb text styling.
          max-width: 320px;
          text-align: center;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 60px 0 45px 0;
          margin: 0;
        }
      }

      .image {
        border: 1px solid $v2GreenDark;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .content {
      .itemsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 20px 20px 30px 20px;

        .item {
          padding: 20px 0;
          border-top: 1px solid $v2GreenDark;

          p {
            font-size: .95rem;
            line-height: 1.3rem;
            margin-bottom: 0;
          }
        }

        .item:first-child {
          border-top: none;
        }
      }
    }

    @media screen and (min-width: 900px) {  
      .imageContainer {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 1px solid $v2GreenDark;
  
        .caption {
          align-items: center;
          width: 50%;

          p {
            padding: 0;
            font-size: 1.75rem;
            line-height: 3rem;
            letter-spacing: 0.05rem;
          }
        }
  
        .image {
          width: 50%;
          border: none;
          border-left: 1px solid $v2GreenDark;
        }
      }
  
      .content {
        .itemsWrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0;
  
          .item {
            padding: 40px 20px 50px 20px;
            border-top: none;
            border-left: 1px solid $v2GreenDark;
            flex: 1;
            display: flex;
            justify-content: center;

            p {
              max-width: 200px;
              font-size: .95rem;
              line-height: 1.3rem;
              margin-bottom: 0;
              text-align: center;
            }
          }

          .item:first-child {
            border-left: none;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper, .imageContainerWrapper {
        padding: 0 60px;
      }

      .content, .imageContainer {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }

      .imageContainer {
        .caption {
          p {
            font-size: 2.25rem;
            line-height: 3.75rem;
            letter-spacing: 0.1rem;
          }
        }
      }

      .content {
        .itemsWrapper {
          .item {
            padding: 40px 20px 60px 20px;

            p {
              font-size: 1rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper, .imageContainerWrapper {
        padding: 0 80px;
      }

      .imageContainer {
        .caption {
          p {
            font-size: 2.5rem;
            line-height: 4rem;
          }
        }
      }

      .content {
        .itemsWrapper {
          .item {
            padding: 60px 20px 75px 20px;

            p {
              max-width: 240px;
              font-size: 1.2rem;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }

  /* Investment & Fees */

  .Investment {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      font-size: .95rem;
      line-height: 1.3rem;
      padding: 30px 20px 0 20px;

      .itemsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .item {
          padding: 20px 0;
          border-bottom: 1px solid $v2GreenDark;

          .label {
            @include WorkSansMedium;
            text-transform: uppercase;
            margin-right: 8px;
          }
        }

        .item:last-child {
          border-bottom: none;
        }
      }
    }

    .imageContainer {
      padding: 20px;
      border-top: 1px solid $v2GreenDark;
      border-bottom: 1px solid $v2GreenDark;

      .image1 {
        border: 1px solid $v2GreenDark;

        img {
          width: 100%;
          height: auto;
        }
      }

      .image2, .image3 {
        display: none;
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        padding: 0;

        .heading {
          display: flex;
          align-items: center;
          padding: 30px 20px;

          p {
            margin: 0;
          }
        }

        .itemsWrapper {
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          flex-wrap: wrap;
  
          .item {
            width: 50%;
            padding: 30px 20px;
            border-top: 1px solid $v2GreenDark;
            border-bottom: none;
          }

          .item:nth-child(even) {
            border-left: 1px solid $v2GreenDark;
          }
        }
      }
  
      .imageContainer {
        padding: 0;
        display: flex;
        justify-content: space-between;
  
        .image1 {
          display: none;
        }
  
        .image2, .image3 {
          display: block;
          width: 50%;
          height: calc(50vw * 0.897);
          max-height: 861.11px;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .image2 {
          background-image: url('../../assets/images/investment-and-fees-2.png');
        }

        .image3 {
          background-image: url('../../assets/images/investment-and-fees-3.png');
          border-left: 1px solid $v2GreenDark;
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper, .imageContainerWrapper {
        padding: 0 80px;
      }

      .content {
        font-size: 1.2rem;
        line-height: 1.75rem;
      }
    }
  }

  /* Next Steps */

  .NextSteps {
    background-color: $v2GreenPale;
    color: $v2GreenDark;
    border-bottom: 1px solid $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      .stepsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0 20px;

        .step {
          width: 100%;
          border-bottom: 1px solid $v2GreenDark;
          display: flex;
          justify-content: space-between;
          align-items: stretch;

          .num {
            @include WorkSansMedium;
            text-transform: uppercase;
            font-size: 1.5rem;
            line-height: 2.25rem;
            padding: 20px 20px 20px 0;
            border-right: 1px solid $v2GreenDark;
          }

          .text {
            flex-grow: 1;
            flex-shrink: 1;
            font-size: .95rem;
            line-height: 1.3rem;
            padding: 30px 20px;
          }
        }

        .step:last-child {
          border-bottom: none;
        }
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        .stepsWrapper {
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 0;
  
          .step {
            width: 25%;
            height: 250px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 40px 20px;
            border: none; // Reset
            border-right: 1px solid $v2GreenDark;
  
            .num {
              padding: 0;
              border-right: none;
              margin-bottom: 30px;
            }
  
            .text {
              padding: 0;
            }
          }

          .step:nth-child(-n + 4) {
            border-bottom: 1px solid $v2GreenDark;
          }

          .step:nth-child(4n) {
            border-right: none;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;

        .stepsWrapper {
          .step {
            height: 270px;

            .num {
              font-size: 1.75rem;
              line-height: 2.5rem;
            }

            .text {
              font-size: 1rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper, .imageContainerWrapper {
        padding: 0 80px;
      }

      .content {
        .stepsWrapper {
          .step {
            height: 300px;

            .num {
              font-size: 2.25rem;
              line-height: 3rem;
            }

            .text {
              font-size: 1.2rem;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }

  /* F.A.Q. */

  .FranchiseFAQ {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      padding: 0 20px;

      .faqItem {
        border-bottom: 1px solid $v2GreenDark;

        .ant-collapse-header {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0;

          .ant-collapse-header-text {
            padding: 30px 30px 30px 0;

            .title {
              @include WorkSansMedium;
              color: $v2GreenDark;
              font-size: .95rem;
              text-transform: uppercase;
              letter-spacing: 0.05rem;
              line-height: 1.3rem;
            }
          }

          .ant-collapse-expand-icon {
            padding: 30px 0;
            text-align: center;

            .panelIcon {
              width: 18px;
              height: auto;
            }
  
            .panelIcon.rotated {
              transform: scaleY(-1);
            }
          }
        }

        .ant-collapse-content .ant-collapse-content-box {
          padding: 0;
          color: $v2GreenDark;

          .answer {
            font-size: .95rem;
            line-height: 1.5rem;
            margin-right: 50px;
            padding-bottom: 20px;

            p {
              margin: 0;
            }
          }
        }
      }

      .faqItem:last-child {
        border-bottom: none;
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        padding: 0;

        .faqItem {
          .ant-collapse-header {
            flex-direction: row;
  
            .ant-collapse-header-text {
              padding: 20px;
              border-left: 1px solid $v2GreenDark;

              .title {
                font-size: 1.1rem;
                line-height: 1.5rem;
              }
            }
  
            .ant-collapse-expand-icon {
              width: 75px;
              padding: 20px 0;

              .panelIcon {
                width: 20px;
              }
            }
          }
  
          .ant-collapse-content .ant-collapse-content-box {            
            .answer {
              line-height: 1.3rem;
              margin-left: 75px; // Width of icon box
              border-left: 1px solid $v2GreenDark;
              padding: 0 0 20px 20px;
              margin-right: 75px; // Width of icon box
            }
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }
  
      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
  
        .faqItem {
          .ant-collapse-header {
            .ant-collapse-header-text {
              .title {
                font-size: 1.2rem;
                line-height: 1.75rem;
              }
            }

            .ant-collapse-expand-icon {
              .panelIcon {
                width: 24px;
              }
            }
          }
  
          .ant-collapse-content .ant-collapse-content-box {            
            .answer {
              font-size: 1rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  
    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }
  
      .content {
        .faqItem {
          .ant-collapse-header {
            .ant-collapse-header-text {
              padding: 30px 20px;

              .title {
                font-size: 1.3rem;
                line-height: 2rem;
              }
            }

            .ant-collapse-expand-icon {
              width: 90px;
              padding: 30px 0;

              .panelIcon {
                width: 30px;
              }
            }
          }
  
          .ant-collapse-content .ant-collapse-content-box {            
            .answer {
              font-size: 1.2rem;
              line-height: 1.75rem;
              margin-left: 90px; // Width of icon box
              margin-right: 90px; // Width of icon box
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }

  /* Custom Footer */

  .FranchiseFooter {
    background-color: $v2GreenDark;
    color: $v2GreenPale;
    padding-bottom: 30px;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      .blurb {
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        img {
          height: 18px;
          width: auto;
          margin-bottom: 40px;
        }

        p {
          padding: 0 20px;
          text-align: center;
          max-width: 340px;
          font-size: 1.2rem;
          line-height: 2rem;
          margin: 0;
        }
    
        .buttonContainer {
          padding-top: 50px;
        }
      }

      .itemWrapper {
        padding: 0 20px;

        .item {
          border-top: 1px solid $v2GreenPale;
          padding: 30px 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          p, .phone, .email {
            text-align: center;
            font-size: .95rem;
            line-height: 1.5rem;
          }

          p {
            max-width: 260px;
            margin-bottom: 30px;
          }
        }
      }
    }

    .subText {
      padding-top: 30px;

      p {
        margin: 0 auto;
        font-size: .65rem;
        line-height: 1rem;
        text-align: center;
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        .blurb {
          padding: 70px 60px;
      
          img {
            height: 20px;
            margin-bottom: 60px;
          }
  
          p {
            max-width: 640px;
            font-size: 1.75rem;
            line-height: 3rem;
            letter-spacing: 0.05rem;
          }
        }
  
        .itemWrapper {
          padding: 0;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
  
          .item {
            flex: 1;
            border-right: 1px solid $v2GreenPale;
            padding: 40px 30px;
          }
  
          .item:last-child {
            border-right: none;
          }
        }
      }
  
      .subText {
        border-top: 1px solid $v2GreenPale;
  
        p {
          padding: 0 30px;
          max-width: 900px;
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;

        .blurb {
          padding: 85px 60px;
      
          img {
            height: 22px;
            margin-bottom: 70px;
          }
  
          p {
            max-width: 740px;
            font-size: 2rem;
            line-height: 3.5rem;
            letter-spacing: 0.075rem;
          }
        }

        .itemWrapper {
          .item {
            padding: 50px 30px;

            p, .phone, .email {
              font-size: 1rem;
            }

            p {
              max-width: 280px;
            }
          }
        }
      }

      .subText {
        p {
          max-width: 1234px;
          padding: 0 90px;
          font-size: .75rem;
          line-height: 1.1rem;
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }
  
      .content {
        .blurb {
          padding: 100px 80px;
      
          img {
            height: 26px;
            margin-bottom: 80px;
          }
  
          p {
            max-width: 830px;
            font-size: 2.25rem;
            line-height: 3.75rem;
            letter-spacing: 0.1rem;
          }
        }

        .itemWrapper {
          .item {
            padding: 60px 30px;
  
            p, .phone, .email {
              font-size: 1.2rem;
              line-height: 1.75rem;
            }
  
            p {
              max-width: 330px;
            }
          }
        }
      }
  
      .subText {
        p {
          max-width: 1366px;
          padding: 0 120px;
          font-size: .85rem;
          line-height: 1.3rem;
        }
      }
    }
  }
}

/* Franchise Info Request */

.FranchiseInfoRequestHeader {
  background-color: transparent;
  border-bottom: 1px solid $v2GreenPale;

  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    display: block;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  
    .leftOption, .rightOption {
      flex: 1;
      display: flex;
    }
  
    .leftOption {
      align-items: center;
      justify-content: flex-start;
    }
  
    .rightOption {
      flex-direction: column;
      align-items: flex-end;
      position: relative;
    }
  
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .closeButton, .logoButton {
      padding: 0;
    }

    .closeButton {
      img {
        height: 18px;
        width: auto;
      }
    }
  
    .logoButton {
      img {
        height: 28px;
        width: auto;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      padding: 0 30px;
      height: 100px;

      .closeButton {
        img {
          height: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentContainer {
      padding: 0 60px;
    }

    .content {
      padding: 0;
    }
  }

  @media screen and (min-width: 1366px) {
    .contentContainer {
      padding: 0 80px;
    }

    .content {
      .closeButton {
        img {
          height: 24px;
        }
      }
    }
  }
}

.FranchiseInfoRequestForm {
  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    padding: 30px 20px;
    font-size: .95rem;
    line-height: 1.5rem;

    .heading {
      margin-bottom: 30px;

      p {
        @include WorkSansMedium;
        text-transform: uppercase;
        letter-spacing: 0.075rem;
        margin: 0;
      }
    }

    .formItems {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .formItem {
        margin-bottom: 30px;
        width: 100%;
      }
    }

    .buttonContainer {
      padding: 30px 0;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      padding: 60px 30px;
      font-size: .85rem;
  
      .heading {
        margin-bottom: 60px;
        max-width: 500px;

        p {
          font-size: 1.2rem;
          line-height: 1.75rem;
        }
      }
  
      .formItems {
        justify-content: flex-start;

        .formItem {
          margin-bottom: 20px;
          width: 47.75%;
        }

        .formItem.email {
          width: 100%;
        }
      }
  
      .buttonContainer {
        justify-content: flex-start;
        padding-top: 60px;
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .content {
      padding: 60px;
      display: flex;
      justify-content: space-between;
  
      .heading {
        max-width: 280px;
        margin-right: 60px;
        margin-bottom: 0;

        p {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
      }

      .formContainer {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .content {
      padding: 120px 80px;
      font-size: .95rem;
  
      .heading {
        margin-right: 80px;
        max-width: 300px;

        p {
          font-size: 1.75rem;
          line-height: 3rem;
        }
      }

      .formContainer {
        max-width: 1234px;

        .formItem {
          margin-bottom: 25px;
          width: 100%;
        }
      }
    }
  }
}
