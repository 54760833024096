.Legal {
  color: $v2GreenPale;

  .documentContainer {
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 5px;
      color: $v2GreenPale;
      max-width: 1920px;
      margin: 0 auto;

      .title {
        font-size: .95rem;
        line-height: 1.3rem;
        letter-spacing: 0.05rem;
        text-align: center;

        h1 {
          @include WorkSansMedium;
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.3rem; // override
          letter-spacing: 0.05rem; // override
          color: $v2GreenPale; // override
        }
      }

      .control {
        width: 30px;
        display: flex;

        button {
          background-color: transparent;
          border: none;
          padding: 15px;
          margin: 0;
          font-size: 1.2rem;
        }
      }

      .disabled {
        button {
          opacity: 0.2;
        }
      }

      .right {
        justify-content: flex-end;
      }

      @media screen and (min-width: 900px) {
        padding: 30px 10px;

        .title {
          h1 {
            font-size: 1.2rem;
            line-height: 1.5rem; // override
          }
        }

        .control {
          button {
            font-size: 1.5rem;
            padding: 20px;
          }
        }
      }

      @media screen and (min-width: 1234px) {
        padding: 30px 60px;
      }

      @media screen and (min-width: 1366px) {
        padding: 40px 80px;
        margin: 0 auto;

        .title {
          font-size: 1.1rem;
          letter-spacing: 0.01rem;
          line-height: 1.75rem;

          h1 {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }

        .control {
          button {
            font-size: 30px;
            padding: 30px;
          }
        }
      }
    }

    .document {
      .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .react-pdf__Page {
        max-width: 100%;
      }
      
      .react-pdf__Page canvas {
        max-width: 100%;
        height: auto !important;
      }

      .react-pdf__message {
        height: calc(100vh - 55px - 100px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .react-pdf__Page__textContent {
        display: none; // TODO: Hides text layer; likely an accessibility issue
      }

      @media screen and (min-width: 900px) {
        padding-bottom: 30px;
        border-bottom: 1px solid $v2GreenPale;

        .react-pdf__message {
          height: calc(100vh - 60px - 90px);
        }
      }

      @media screen and (min-width: 1234px) {
        padding-bottom: 60px;

        .react-pdf__message {
          height: calc(100vh - 100px - 125px);
        }
      }

      @media screen and (min-width: 1234px) {
        padding-bottom: 80px;

        .react-pdf__message {
          height: calc(100vh - 100px - 170px);
        }
      }
    }
  }
}
