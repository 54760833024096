/* General */

body {
  @include SimplonMonoRegular;
  background-color: $v2GreenDark;
  color: $v2GreenPale;
  font-size: 1rem;
  line-height: auto;
}

h1 {
  @include SimplonMonoRegular;
  font-size: 2.75rem;
  line-height: 4rem;
  letter-spacing: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  
  .italic {
    @include WorkSansMediumItalic;
  }

  @media screen and (min-width: 900px) {
    font-size: 3.5rem;
    line-height: 4.75rem;
    letter-spacing: 0.3rem;
  }

  @media screen and (min-width: 1366px) {
    font-size: 4rem;
    line-height: 5.75rem;
    letter-spacing: 0.4rem;
  }
}

h2 {
  @include WorkSansMedium;
  text-decoration: none;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.2rem;
  text-align: left;
  padding: 0;
  margin: 0;
}

/* Header Bar */

.HeaderBar {
  background-color: transparent;
  border-bottom: 1px solid $v2GreenDark;

  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    display: block;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  
    .leftOption, .rightOption {
      flex: 1;
      display: flex;
    }
  
    .leftOption {
      align-items: center;
      justify-content: flex-start;
    }
  
    .rightOption {
      flex-direction: column;
      align-items: flex-end;
      position: relative;
    }
  
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .menuButton, .logoButton {
      padding: 0;
    }

    .menuButton {
      img {
        height: 18px;
        width: auto;
      }
    }
  
    .logoButton {
      img {
        height: 28px;
        width: auto;
      }
    }

    .actionButton {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      padding: 0;
      height: 100px;

      .leftOption, .rightOption {
        flex: 1;
      }

      .menuButton {
        margin-left: 30px;
        img {
          height: 20px;
          width: auto;
        }
      }

      .actionButton {
        height: 100%;
        width: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $v2GreenDark;
        color: $v2GreenPale;
        border: none;
        border-radius: 0;
        padding: 0 15px;

        span {
          @include SimplonMonoRegular;
          position: relative;
          text-transform: uppercase;
          color: $v2GreenPale;
          font-size: 0.8rem;
          letter-spacing: 0.05rem;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: $v2GreenPale;
            transform-origin: bottom right;
            transition: transform 0.5s ease-out;
          }
          
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentContainer {
      padding: 0 60px;
    }

    .content {
      border-left: 1px solid $v2GreenDark;
      border-right: 1px solid $v2GreenDark;

      .menuButton {
        display: none;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .contentContainer {
      padding: 0 80px;
    }
  }
}

.HeaderBar.light {
  border-bottom: 1px solid $v2GreenPale;

  .content {
    .actionButton {
      background-color: $v2GreenPale;

      span {
        color: $v2GreenDark;

        &::after {
          background-color: $v2GreenDark;
        }
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .content {
      border-left: 1px solid $v2GreenPale;
      border-right: 1px solid $v2GreenPale;
    }
  }
}

/* Navigation */

.mobileMenuCloseIcon {
  img {
    height: 18px;
    width: auto;
  }
}

.MobileNav {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .option {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include WorkSansRegular;
    text-transform: uppercase;
    color: $v2GreenDark;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    padding: 20px;

    &:hover, &:active {
      background-color: white;
    }
  }

  .option.italic {
    text-transform: none;
    @include WorkSansMediumItalic;
  }

  @media screen and (min-width: 900px) {
    .option {
      padding: 30px;
    }
  }
}

.DesktopNav {
  display: none;

  @media screen and (min-width: 1234px) {
    display: flex;
    padding-left: 10px;

    .option {
      padding: 10px;
      margin-right: 10px;

      span {
        @include WorkSansRegular;
        position: relative;
        text-transform: uppercase;
        color: $v2GreenDark;
        font-size: .9rem;
        padding-bottom: 6px;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: $v2GreenDark;
          transform-origin: bottom right;
          transition: transform 0.5s ease-out;
        }
        
        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }

    .option.italic {
      span  {
        text-transform: none;
        @include WorkSansMediumItalic;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    padding-left: 15px;

    .option {
      padding: 15px;
    }
  }
}

.DesktopNav.light {
  .option {
    span {
      color: $v2GreenPale;

      &::after {
        background-color: $v2GreenPale;
      }
    }
  }
}

.StoreNav {
  position: absolute;
  top: 100px; // Height of header bar
  display: none;
  flex-direction: column;
  align-items: stretch;
  background-color: $v2GreenPale;
  width: 280px;
  padding: 0 15px;
  border-top: 1px solid $v2GreenDark;
  transition: opacity 0.25s;
  opacity: 0;

  button {
    height: 60px;
    @include SimplonMonoRegular;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    color: $v2GreenDark;
    background-color: transparent;
    border: none;
    border-top: 1px solid $v2GreenDark;
    border-radius: 0;

    .typeLabel {
      text-transform: uppercase;
    }
  }

  button:first-child {
    border-top: none;
  }

  button.current {
    background-color: $v2GreenDark;
    color: $v2GreenPale;
  }
}

.StoreNav.visible {
  opacity: 1;
}

.StoreNav.show {
  display: flex;
}

/* Section Heading */

.SectionHeading {
  color: $v2GreenPale;
  background-color: $v2GreenDark;
  border-bottom: 1px solid $v2GreenPale;

  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    height: 75px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include WorkSansMedium;
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
  }

  .circle {
    font-size: .85rem;
    margin-right: 15px;
  }

  @media screen and (min-width: 900px) {
    .content {
      height: 100px;
      font-size: 1.3rem;
    }

    .circle {
      font-size: .95rem;
    }
  }

  @media screen and (min-width: 1234px) {
    .contentContainer {
      padding: 0 60px;
    }
  }

  @media screen and (min-width: 1366px) {
    .contentContainer {
      padding: 0 80px;
    }

    .content {
      height: 120px;
      padding: 0 30px;
      font-size: 1.75rem;
    }

    .circle {
      font-size: 1.1rem;
      margin-right: 20px;
    }
  }
}

.SectionHeading.light {
  color: $v2GreenDark;
  background-color: $v2GreenPale;
  border-bottom: 1px solid $v2GreenDark;
}

/* Blurb */

.Blurb {
  background-color: $v2GreenDark;
  color: $v2GreenPale;

  .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    padding: 50px 20px 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 18px;
      width: auto;
      margin-bottom: 40px;
    }

    .heading {
      @include WorkSansMedium;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.075rem;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      max-width: 300px;
      text-align: center;
      font-size: 1.2rem;
      line-height: 2rem;
      margin: 0;
    }

    .buttonContainer {
      padding-top: 50px;
    }
  }

  .content.larger {
    p {
      max-width: 320px;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      padding: 70px 60px 80px 60px;

      img {
        height: 20px;
        margin-bottom: 60px;
      }

      .heading {
        font-size: 0.9rem;
        margin-bottom: 30px;
      }
  
      p {
        max-width: 500px;
        font-size: 1.75rem;
        line-height: 3rem;
        letter-spacing: 0.05rem;
      }
    }

    .content.larger {
      p {
        max-width: 640px;
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentWrapper {
      padding: 0 60px;
    }

    .content {
      border-right: 1px solid $v2GreenPale;
      border-left: 1px solid $v2GreenPale;
      padding: 85px 60px 90px 60px;

      img {
        height: 22px;
        margin-bottom: 70px;
      }

      .heading {
        font-size: 1rem;
        line-height: 1.6rem;
      }
  
      p {
        max-width: 520px;
        font-size: 2rem;
        line-height: 3.5rem;
        letter-spacing: 0.075rem;
      }

      .buttonContainer {
        padding-top: 60px;
      }
    }

    .content.larger {
      p {
        max-width: 740px;
        font-size: 2.25rem;
        line-height: 3.75rem;
        letter-spacing: 0.1rem;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .contentWrapper {
      padding: 0 80px;
    }

    .content {
      padding: 100px 80px 110px 80px;

      img {
        height: 26px;
        margin-bottom: 80px;
      }

      .heading {
        font-size: 1.1rem;
        line-height: 2.2rem;
      }
  
      p {
        max-width: 600px;
        font-size: 2.25rem;
        line-height: 3.75rem;
        letter-spacing: 0.1rem;
      }

      .buttonContainer {
        padding-top: 80px;
      }
    }

    .content.larger {
      p {
        max-width: 800px;
        font-size: 2.5rem;
        line-height: 4rem;
      }
    }
  }
}

/* Footer Bar */

.Footer {
  background-color: $v2GreenDark;
  color: $v2GreenPale;
  padding: 0;

  .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    font-size: .95rem;
    letter-spacing: 0.05rem;
    line-height: 1.3rem;

    .connect {
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $v2GreenPale;

      .heading {
        @include WorkSansMedium;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .social {
        margin-bottom: 20px;

        a {
          margin: 0 20px;

          .icon.instagram {
            font-size: 3.2rem;
          }

          .icon.tiktok {
            font-size: 2.7rem;
          }
        }
      }

      .email {
        text-align: center;
        text-transform: lowercase;
        font-size: .85rem;
        line-height: 1.3rem;
      }
    }

    .navigation {
      padding-top: 30px;
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .legal, .pages {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 20px;

        a, .option {
          @include WorkSansMedium;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

      }
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 60px 20px;

      .connect {
        width: 40%;
        padding: 0;
        border: none;
        align-items: flex-start;

        .social {
          a {
            margin: 0;
            margin-right: 40px;
          }
        }

        .email {
          @include WorkSansMedium;
          text-transform: uppercase;
          font-size: inherit;
          line-height: inherit;
        }
      }

      .navigation {
        width: 60%;
        padding: 0;

        .legal, .pages {
          padding: 0;
          padding-right: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentWrapper {
      padding: 0 60px;
    }

    .content {
      .connect {
        width: 30%;
      }

      .navigation {
        width: 70%;

        .legal, .pages {
          padding-right: 80px;
        }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .contentWrapper {
      padding: 0 80px;
    }

    .content {
      padding: 80px 30px;
      font-size: 1.1rem;
      letter-spacing: 0.01rem;
      line-height: 1.75rem;

      .connect {
        width: 30%;

        .heading, .social {
          margin-bottom: 30px;
        }
      }

      .navigation {
        width: 70%;

        .legal, .pages {
          padding-right: 80px;
        }
      }
    }
  }
}

/* Subscribe Form */

.SubscribeForm {
  background-color: $v2GreenDark;
  color: $v2GreenPale;
  border-bottom: 1px solid $v2GreenPale;

  .contentWrapper {
    max-width: 1920px;
    margin: 0 auto;
  }

  .content {
    padding: 40px 20px;
    font-size: .95rem;
    letter-spacing: 0.05rem;
    line-height: 1.3rem;

    .introContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
  
      .heading {
        @include WorkSansMedium;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
  
      .text {
        margin-bottom: 30px;
      }
    }
  
    .formContent {
      padding-bottom: 15px;
  
      .emailInputContainer {
        margin-bottom: 30px;
      }
  
      .locationAndSubmissionButtonContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
  
        .locationInputContainer {
          flex: 1;
        }
  
        .submissionButtonContainer {
          flex: 1;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .content {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      // Using hard widths vs. flex here
      .introContainer {
        width: 33.3%;

        .heading {
          margin-bottom: 30px;
        }

        .text {
          max-width: 360px;
          margin: 0;
        }
      }

      .formContent {
        width: 66.6%;
        padding: 36px 0 0 60px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .emailInputContainer {
          width: 40%;
          margin: 0;
        }

        .locationAndSubmissionButtonContainer {
          width: 60%;
          padding-left: 30px;

          .locationInputContainer {
            flex: none;
            width: 100px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1234px) {
    .contentWrapper {
      padding: 0 60px;
    }

    .content {
      .formContent {
        .locationAndSubmissionButtonContainer {
          padding-left: 60px;
        }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .contentWrapper {
      padding: 0 80px;
    }

    .content {
      padding: 50px 30px;
      font-size: 1.1rem;
      letter-spacing: 0.01rem;
      line-height: 1.75rem;

      .formContent {
        .locationAndSubmissionButtonContainer {
          padding-left: 60px;
        }
      }
    }
  }
}

/* Buttons */

.animatedButton {
  display: inline-block;
  height: 44px;
  position: relative;
  cursor: pointer;

  .layer1, .layer2, .layer3 {
    // NOTE: Not using flex here is important!
    @include SimplonMonoRegular;
    position: absolute;
    height: 44px;
    width: 100%;
    border-radius: 22px;
    padding: 0 22px;
    text-transform: uppercase;
    line-height: 1.25rem;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    border: 1px solid;
  }
  
  // Normal styling
  .layer1 {
    z-index: 1;
    background-color: $v2GreenPale;
    border-color: $v2GreenPale;
    color: transparent;
  }
  
  // Hover styling; displayed only on hover/focus
  .layer2 {
    z-index: 2;
    display: none;
    background-color: $v2GreenDark;
    border-color: $v2GreenPale;
    color: transparent;
  }
  
  // The actual button, minus border
  .layer3 {
    z-index: 3;
    background-color: transparent;
    border-color: transparent;
    color: $v2GreenDark;
  }

  .logoSpinnerContainer {
    display: inline-block;
    height: 1.125rem;
    width: 1.125rem;
  }

  @media screen and (min-width: 900px) {
    .layer1, .layer2, .layer3 {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 1366px) {
    .layer1, .layer2, .layer3 {
      font-size: 0.9rem;
    }
  }
}

.animatedButton:is(:hover, :focus) {
  .layer1 {
    background-color: $v2GreenDark;
    border-color: $v2GreenDark;
  }

  .layer2 {
    display: inline-block;
    animation: circleDraw 0.5s linear;
  }

  .layer3 {
    color: $v2GreenPale;
  }
}

.animatedButton.active {
  .layer1 {
    background-color: $v2GreenDark;
    border-color: $v2GreenPale;
  }

  .layer2 {
    display: flex;
  }

  .layer3 {
    color: $v2GreenPale;
  }
}

.animatedButton.dark {
  .layer1 {
    background-color: $v2GreenDark;
    border-color: $v2GreenDark;
  }
  
  .layer2 {
    background-color: $v2GreenPale;
    border-color: $v2GreenDark;
  }
  
  .layer3 {
    color: $v2GreenPale;
  }
}

.animatedButton.dark:is(:hover, :focus),
.animatedButton.dark.active {
  .layer1 {
    background-color: $v2GreenPale;
    border-color: $v2GreenPale;
  }

  .layer3 {
    color: $v2GreenDark;
  }
}

.animatedButton.landingBanner,
.animatedButton.franchiseBanner {
  width: 180px;
  height: 70px;

  .layer1, .layer2, .layer3 {
    height: 70px;
    border-radius: 35px;
    padding: 0 35px;
  }

  @media screen and (min-width: 900px) {
    width: 240px;
    height: 52px;

    .layer1, .layer2, .layer3 {
      height: 52px;
      border-radius: 26px;
      padding: 0 26px;
    }
  }

  @media screen and (min-width: 1366px) {
    width: 260px;
  }
}

.animatedButton.franchiseFooter {
  width: 240px;

  @media screen and (min-width: 900px) {
    width: 220px;
  }

  @media screen and (min-width: 1366px) {
    width: 240px;
  }
}

.animatedButton.franchiseInfoRequestForm {
  width: 160px;

  @media screen and (min-width: 900px) {
    height: 52px;

    .layer1, .layer2, .layer3 {
      height: 52px;
      border-radius: 26px;
      padding: 0 26px;
    }
  }
}

.animatedButton.signup {
  width: 113px;
}

.animatedButton.viewStore {
  width: 150px;
}

.animatedButton.requestInfo {
  width: 240px;
}

.animatedButton.getDirections {
  width: 140px;
}

.animatedButton.medicalMenuButton {
  width: 120px;

  @media screen and (min-width: 900px) {
    width: 150px;
  }

  @media screen and (min-width: 1366px) {
    width: 165px;
  }
}

.animatedButton.recreationalMenuButton {
  width: 160px;

  @media screen and (min-width: 900px) {
    width: 195px;
  }

  @media screen and (min-width: 1366px) {
    width: 210px;
  }
}

.animatedButton.deliverySignupButton {
  width: 240px;
}

/* Forms */

input, select {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $v2GreenPale;
  padding: 10px 0;
  width: 100%;

  &::placeholder {
    color: $v2GreenPale;
    opacity: 1.0;
  }
}

select {
  // Custom chevron
  -moz-appearance: none; // Mozilla
  -webkit-appearance: none; // other browsers
  appearance: none; // experimental
  background-image: url('../assets/images/chevron-down-pale.png');
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: top 14px right 5px;
  option {
    color: $v2GreenPale;
  }
}

// Google reCAPTCHA style overrides
.grecaptcha-badge {
  display: none; // Reset
}

.grecaptcha-badge {
  z-index: 999;
  display: block;
}