/* General */

.About {
  padding-top: 55px; // Compensating for fixed nav bar
  @media screen and (min-width: 768px) {
    padding-top: 80px; // Compensating for fixed nav bar
  }
  @media screen and (min-width: 1366px) {
    padding-top: 0px; // No fixed nav bar
  }
  .sectionDivider {
    width: 261px;
    height: 40px;
    margin: 0 auto;
    border-bottom: solid $greenDark 1px;
    display: flex;
    justify-content: center;
    .centerPost {
      height: 40px;
      border-right: solid $greenDark 1px;
    }
    @media screen and (min-width: 768px) {
      width: 621px;
      height: 50px;
      .centerPost {
        height: 50px;
      }
    }
    @media screen and (min-width: 1366px) {
      width: 1031px;
      height: 85px;
      .centerPost {
        height: 85px;
      }
    }
  }
}

/* Cultivation Experience */

.Cultivation {
  .backgroundColor {
    background-color: $greenLight;
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    height: 370px;
    @media screen and (min-width: 768px) {
      height: 360px;
    }
    @media screen and (min-width: 1366px) {
      margin-top: -180px;
      height: 860px;
    }
  }
  .subHeading {
    width: 261px;
    margin: 0 auto;
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      width: 580px;
    }
    @media screen and (min-width: 1366px) {
      width: 820px;
    }
  }
  .plantImage {
    width: 120px;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
      width: 140px;
    }
    @media screen and (min-width: 1366px) {
      width: 270px;
    }
    img {
      width: 100%;
    }
  }
  .cultivationInfoWrapper {
    width: 261px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    @media screen and (min-width: 768px) {
      padding-top: 40px;
      padding-bottom: 20px;
    }
    @media screen and (min-width: 1366px) {
      width: 1234px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .infoSelectContainer {
      display: none;
      @media screen and (min-width: 1366px) {
        display: block;
        width: 100%;
        height: 180px;
        .static {
          width: 100%;
          height: 180px;
          padding-top: 30px;
          .infoSelect {
            width: 800px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .indicator {
              @include SofiaProRegular;
              color: $brownDarker;
              font-size: 20px;
              line-height: 27px;
              padding: 12px 22px;
              border-radius: 50%;
              border: solid transparent 1px; // Invisible border
            }
            .selected {
              border-color: $greenDark;
            }
          }
        }
        .fixed {
          width: 100%;
          height: 180px;
          padding-top: 30px;
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          z-index: 10; // May need to be adjusted
          background: linear-gradient($whiteBackground 50%, rgba(255,255,255, 0.001));
          .infoSelect {
            width: 800px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .indicator {
              @include SofiaProRegular;
              color: $brownDarker;
              font-size: 20px;
              line-height: 27px;
              padding: 12px 22px;
            }
            .circled {
              span {
                border-radius: 50%;
                border: solid $greenDark 1px;
              }
            }
          }
        }
      }
    }
    .infoContainer {
      width: 100%;
      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 40px;
        .image {
          margin-bottom: 30px;
          img {
            width: 100%;
          }
        }
        @media screen and (min-width: 1366px) {
          flex-direction: row;
          margin-bottom: 115px;
          opacity: 0;

          transition-property: opacity;
          transition-duration: 0.5s;
          .image {
            width: calc(1234px / 2); // Half of content width
            img {
              width: 100%;
              height: 451px;
            }
          }
        }
        .content {
          h3 {
            text-align: center;
            margin-bottom: 20px;
          }
          p {
            text-align: center;
          }
          @media screen and (min-width: 1366px) {
            width: calc(1234px / 2); // Half of content width
            padding-left: 100px;
            padding-top: 60px;
            h3 {
              text-align: left;
              margin-bottom: 40px;
            }
            p {
              text-align: left;
              width: 400px;
            }
          }
        }
      }
      @media screen and (min-width: 1366px) {
        .current {
          opacity: 1;
        }
      }
    }
  }
  .sustainability {
    h3 {
      text-align: center;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1366px) {
      padding-bottom: 115px;
      h3 {
        margin-bottom: 115px;
      }
    }
    .featureContainer {
      width: 261px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        width: 621px;
        flex-direction: row;
        justify-content: space-between;
      }
      @media screen and (min-width: 1366px) {
        width: 1031px;
        flex-direction: row;
        justify-content: space-between;
      }
      .feature {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        .image {
          height: 50px;
          margin-bottom: 10px;
          img {
            height: 100%;
          }
        }
        @media screen and (min-width: 768px) {
          width: 150px;
        }
        @media screen and (min-width: 1366px) {
          width: 210px;
          margin-bottom: 0;
          .image {
            height: 82px;
            margin-bottom: 30px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            @include SofiaProLight;
            text-align: center;
          }
          .divider {
            width: 10px;
            border-bottom: solid $brownDarker 1px;
            padding-top: 13px;
            margin-bottom: 13px;
            @media screen and (min-width: 1366px) {
              width: 20px;
              padding-top: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}


/* Retail Experience */

.Retail {
  background-color: $greenDark;
  color: $brownPale;
  .sectionDivider {
    border-bottom: solid $brownPale 1px;
    .centerPost {
      border-right: solid $brownPale 1px;
    }
  }
  h2, h3, p {
    color: inherit;
  }
  .infoContainer {
    width: 261px;
    margin: 0 auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .info {
      margin-bottom: 30px;
      h3, p {
        text-align: center;
      }
      h3 {
        margin-bottom: 10px;
      }
    }
    @media screen and (min-width: 768px) {
      width: 621px;
      margin-bottom: 60px;
      flex-direction: row;
      justify-content: space-between;
      .info {
        margin-bottom: 0;
        width: 261px;
      }
    }
    @media screen and (min-width: 1366px) {
      width: 1031px;
      margin-bottom: 80px;
      .info {
        width: 411px;
        h3 {
          margin-bottom: 40px;
        }
      }
    }
  }
  .subHeading {
    width: 261px;
    margin: 0 auto 40px auto;
    @media screen and (min-width: 768px) {
      width: 621px;
    }
    @media screen and (min-width: 1366px) {
      width: 820px;
      margin-bottom: 65px;
    }
  }
  .retailImage {
    width: 261px;
    margin: 0 auto 40px auto;
    @media screen and (min-width: 768px) {
      width: 621px;
    }
    @media screen and (min-width: 1366px) {
      width: 1031px;
      margin-bottom: 65px;
    }
    img {
      width: 100%;
    }
  }
  .sustainability {
    h3 {
      text-align: center;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1366px) {
      padding-bottom: 65px;
      h3 {
        margin-bottom: 65px;
      }
    }
    .featureContainer {
      width: 261px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        width: 621px;
        flex-direction: row;
        justify-content: space-between;
      }
      @media screen and (min-width: 1366px) {
        width: 1031px;
      }
      .feature {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        @media screen and (min-width: 768px) {
          width: 150px;
        }
        .image {
          height: 50px;
          margin-bottom: 10px;
          img {
            height: 100%;
          }
        }
        @media screen and (min-width: 1366px) {
          width: 210px;
          margin-bottom: 0;
          .image {
            height: 82px;
            margin-bottom: 30px;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            @include SofiaProLight;
            text-align: center;
          }
          .divider {
            width: 10px;
            border-bottom: solid $brownPale 1px;
            padding-top: 13px;
            margin-bottom: 13px;
            @media screen and (min-width: 1366px) {
              width: 20px;
              padding-top: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

/* Social Responsibility */

.Responsibility {
  @media screen and (min-width: 768px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1366px) {
    padding-bottom: 0;
  }
  .backgroundColor {
    background-color: $greenLight;
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    height: 370px;
    @media screen and (min-width: 768px) {
      height: 330px;
    }
    @media screen and (min-width: 1366px) {
      height: 700px;
    }
  }
  .subHeading {
    width: 261px;
    margin: 0 auto 30px auto;
    @media screen and (min-width: 768px) {
      width: 621px;
      margin-bottom: 40px;
    }
    @media screen and (min-width: 1366px) {
      width: 820px;
      margin-bottom: 80px;
    }
  }
  .infoContainer {
    width: 261px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media screen and (min-width: 1366px) {
      width: 1031px;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 65px;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      @media screen and (min-width: 1366px) {
        width: 450px;
        padding: 0 30px;
        margin-bottom: 0;
      }
      .image {
        height: 120px;
        margin-bottom: 30px;
        @media screen and (min-width: 768px) {
          height: 140px;
        }
        @media screen and (min-width: 1366px) {
          height: 354px;
        }
        img {
          height: 100%;
        }
      }
      .content {
        h3, p {
          text-align: center;
        }
        h3 {
          margin-bottom: 10px;
          @media screen and (min-width: 1366px) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
