/* Mobile Nav Bar */

.navBar {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: $whiteBackground;
  @media screen and (min-width: 768px) {
    height: 80px;
    padding: 0 20px;
  }
  button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  .menuButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
    width: 40px;
    line-height: 20px;
    padding-left: 5px;
    .icon {
      font-size: 20px;
    }
  }
  .logoButton {
    .image {
      img {
        height: 20px;
        width: 100px;
      }
    }
  }
  .spinningTextContainer {
    display: relative;
    height: 40px;
    width: 40px;
    .spinningText {
      display: absolute;
      height: 40px;
      width: 40px;
      img {
        height: 100%;
      }
    }
  }
}

/* Mobile Menu */

.mobileMenuBackground {
  position: fixed;
  z-index: 99;
  top: 55px; // Compensating for nav bar
  right: 0;
  left: 0;
  height: calc(100vh - 55px); // May be an issue in Safari on iOS if menu items span full height of container
  background-color: rgba(255, 250, 245, 0.5); // $whiteBackground @ 30%
  nav.mobile {
    width: 100%;
    overflow: auto;
    .navButton {
      height: 60px;
      width: 100%;
      color: $brownPaler;
      @include SofiaProRegular;
      font-size: 14px;
      line-height: 27px;
      background-color: $brownDark;
      border: none;
      border-bottom: solid $brownPaler 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      .parts {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }
      .left {
        width: calc(50% + 10px);
        justify-content: flex-end;
      }
      .right {
        width: calc(50% - 10px);
        justify-content: flex-start;
      }
      .chevron img {
        margin-left: 5px;
        height: 7px;
        width: 12px;
      }
      .flipped {
        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);
      }
    }
    .sub {
      color: $brownDark;
      background-color: $brownPale;
      border-bottom-color: $brownDark;
    }
  }
  @media screen and (min-width: 768px) {
    top: 80px; // Compensating for nav bar
    height: calc(100vh - 80px); // May be an issue in Safari on iOS if menu items span full height of container
  }
}

/* Desktop Nav */

nav.desktop {
  width: calc(1234px + 45px); // Width of content + (padding of buttons * 2)
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; // For absolutely positioned store list
  .navButtonContainer {
    width: 150px;
    .navButton {
      display: inline; // Could be a button or a DIV
      border: solid transparent 1px;
      transition: border-color 0.2s;
      &:hover {
        border-color: $greenDark;
      }
      .parts {
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }
      .chevron img {
        margin-left: 7px;
        height: 8.5px;
        width: 17px;
      }
      .flipped {
        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);
      }
    }
    .selected {
      border-color: $greenDark;
    }
  }
  .logoButtonContainer {
    .logoButton {
      background: transparent;
      border: none;
      .image {
        img {
          height: 37px;
          width: 185px;
        }
      }
    }
  }
}

/* Store Sub-Menu */

.storeList {
  position: absolute;
  right: 22.5px;
  z-index: 1;
  top: 100px; // Height of top nav bar
  background-color: $whiteBackground;
  padding: 0 15px 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .topDivider {
    height: 1px;
    background-color: $brownDarker;
  }
  button {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px $brownDarker;
    cursor: pointer; 
    padding: 10px;
    @include SofiaProRegular;
    font-size: 20px;
    line-height: 27px;
    color: $brownDarker;
  }
}
