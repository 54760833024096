.Landing {
  position: relative;

  /* Banner */

  .BannerVideo {
    object-fit: cover;
    width: calc(100vw - (100vw - 100%)); // Compensate for scrollbar
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (min-width: 1366px) {
      max-height: 900px;
    }
  }

  .Banner {
    position: relative;
    height: 100vh;
    min-height: 600px;
    max-height: 768px;
    background-color: rgba(0, 0, 0, 0.15);

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
      height: calc(100vh - 60px); // compensating for header bar height
      min-height: calc(600px - 60px); // compensating for header bar height
      max-height: calc(768px - 60px); // compensating for header bar height
      padding: 100px 0;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          @include WorkSansMedium;
          color: $v2GreenPale;
          margin-bottom: 60px;
        }
      }
    }

    @media screen and (min-width: 900px) {
      .contentWrapper {
        height: calc(100vh - 100px); // compensating for header bar height
        min-height: calc(600px - 100px); // compensating for header bar height
        max-height: calc(768px - 100px); // compensating for header bar height
        padding: 0px 60px;
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        .content {
          border-right: 1px solid $v2GreenPale;
          border-left: 1px solid $v2GreenPale;
        }
      }
    }

    @media screen and (min-width: 1366px) {
      max-height: 900px;

      .contentWrapper {
        padding: 0px 80px;
        max-height: calc(900px - 100px); // compensating for header bar height
      }
    }
  }

  /* Get Started */

  .GetStarted {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .marquee {
      height: 75px;
      border-bottom: 1px solid $v2GreenDark;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;

      .content {
        @include WorkSansMedium;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: $v2GreenDark;
        animation: marquee 20s linear infinite;
        display: flex;
        align-items: center;

        .circle {
          font-size: 0.85rem;
          margin: 0 10px;
        }
      }
    }

    @keyframes marquee {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-50%, 0);
      }
    }

    .stepsWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .step {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 0 20px;

        .sideImage,
        .content {
          flex: 1;
        }

        .sideImage {
          padding: 30px 20px 30px 0;
          border-bottom: 1px solid $v2GreenDark;
          text-align: center;

          img {
            width: 100%;
            max-width: 200px;
            height: auto;
          }
        }

        .content {
          padding: 30px 0 30px 20px;
          border-left: 1px solid $v2GreenDark;
          border-bottom: 1px solid $v2GreenDark;
          font-size: 0.95rem;
          line-height: 1.3rem;

          .heading {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;

            .number {
              text-transform: uppercase;
            }

            .title {
              @include WorkSansMedium;
              text-transform: uppercase;
              letter-spacing: 0.075rem;
            }
          }

          .contentImage {
            display: none;
          }

          .description {
            margin: 0;
          }

          .disclaimer {
            margin: 0;
            font-size: 0.7rem;
            line-height: 1rem;
            margin-top: 20px;
          }
        }
      }

      .step.fullBottomBorder {
        border-bottom: 1px solid $v2GreenDark;

        .sideImage,
        .content {
          border-bottom: none;
        }
      }
    }

    @media screen and (min-width: 900px) {
      .contentWrapper {
        border-bottom: 1px solid $v2GreenDark;
      }

      .marquee {
        height: 100px;

        .content {
          font-size: 1.3rem;

          .circle {
            font-size: 0.95rem;
          }
        }
      }

      .stepsWrapper {
        flex-direction: row;

        .step {
          flex: 1;
          display: block;
          padding: 0;
          padding-bottom: 40px;
          position: relative;

          .sideImage {
            display: none;
          }

          .content {
            padding: 0;
            border-left: none;
            border-bottom: none;
            font-size: 0.85rem;

            .heading {
              align-items: center;

              .number {
                width: 100%;
                text-align: center;
                padding: 15px;
                border-bottom: 1px solid $v2GreenDark;
                margin-bottom: 30px;

                .colon {
                  display: none;
                }
              }

              .title {
                text-align: center;
                font-size: 0.95rem;
                max-width: 120px;
              }
            }

            .contentImage {
              display: block;
              padding: 30px 0;
              text-align: center;
              height: 180px;

              img {
                max-width: 220px;
                height: auto;
              }
            }

            .description {
              max-width: 320px;
              text-align: center;
              margin: 0 auto;
              padding: 30px;
            }

            .disclaimer {
              width: 100%;
              margin: 0;
              position: absolute;
              bottom: 15px;
              text-align: center;
            }
          }
        }

        .step.middle {
          border-left: 1px solid $v2GreenDark;
          border-right: 1px solid $v2GreenDark;
        }

        .step.fullBottomBorder {
          border-bottom: none;

          .image,
          .content {
            border-bottom: none;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .marquee,
      .stepsWrapper {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .marquee {
        height: 120px;

        .content {
          font-size: 1.75rem;

          .circle {
            font-size: 1.1rem;
            margin: 0 15px;
          }
        }
      }

      .stepsWrapper {
        .step {
          padding-bottom: 60px;

          .content {
            font-size: 1.1rem;
            line-height: 1.75rem;

            .heading {
              margin-bottom: 30px;

              .number {
                padding: 20px;
                margin-bottom: 40px;
              }

              .title {
                font-size: 1.2rem;
                max-width: 150px;
              }
            }

            .contentImage {
              height: 260px;

              img {
                max-width: 300px;
              }
            }

            .disclaimer {
              font-size: 0.85rem;
              line-height: 1.1rem;
              bottom: 20px;
            }
          }
        }
      }
    }
  }

  /* Sustainability */

  .Sustainability {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      .videoContainerPadding {
        padding: 20px 20px 0 20px;
        margin-bottom: 30px;
      }

      .videoContainer {
        height: calc(100% * 1.10429);

        .video {
          display: flex;
          width: 100%;
          height: 100%;
        }
      }

      .itemsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .item {
          padding: 0 20px;
          margin-bottom: 30px;
          font-size: 0.85rem;
          line-height: 1.3rem;
          letter-spacing: 0.03rem;

          .title {
            @include WorkSansMedium;
            text-transform: uppercase;
            margin-bottom: 15px;
          }

          .description {
            border-bottom: 1px solid $v2GreenDark;
            padding-bottom: 30px;
            margin-bottom: 0px;
          }
        }

        .item.fullBottomBorder {
          border-bottom: 1px solid $v2GreenDark;
          margin-bottom: 0;

          .description {
            border-bottom: none;
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      border-bottom: 1px solid $v2GreenDark;

      .content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: stretch;

        .videoContainerPadding {
          width: 50%;
          height: calc(50% * 1.10429);
          padding: 0;
          margin-bottom: 0;
        }

        .videoContainer {
          height: 100%;
        }

        .itemsWrapper {
          width: 50%;
          border-right: 1px solid $v2GreenDark;

          .item {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0;
            border-bottom: 1px solid $v2GreenDark;

            .title {
              font-size: 0.95rem;
            }

            .description {
              border-bottom: none;
              padding-bottom: 0;
            }
          }

          .item.fullBottomBorder {
            border-bottom: none;
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .content {
        .itemsWrapper {
          .item {
            padding: 0 30px;
            font-size: 1.1rem;
            line-height: 1.75rem;
            letter-spacing: 0.05rem;

            .title {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  /* Locations */

  .Locations {
    background-color: $v2GreenPale;
    color: $v2GreenDark;
    border-bottom: 1px solid $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .content {
      .storeImage {
        padding: 20px 20px 0 20px;

        .imageBackground {
          width: 100%;
          height: calc((100vw - 40px) * 1.23625);
          background-image: url("../../assets/images/store-interior.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .storesWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0 20px;

        .store {
          border-bottom: 1px solid $v2GreenDark;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          height: 100px;
          font-size: 0.85rem;
          line-height: 1.3rem;
          letter-spacing: 0.03rem;

          .info {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .name {
              @include WorkSansMedium;
              text-transform: uppercase;
            }

            .type {
              margin-top: 5px;
              text-transform: capitalize;
              font-size: 0.65rem;
              letter-spacing: 0.01rem;
            }
          }

          .controls {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            .map {
              width: 90px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-left: 1px solid $v2GreenDark;
              border-right: 1px solid $v2GreenDark;

              .link {
                text-decoration: underline;
              }
            }

            .menu {
              width: 170px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }

          .comingSoon {
            width: 200px;
            display: flex;
            align-items: center;
            padding: 30px;
            border-left: 1px solid $v2GreenDark;
          }
        }

        .store:last-child {
          border-bottom: none;
        }
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;

        .storeImage,
        .storesWrapper {
          flex: 1;
        }

        .storeImage {
          padding: 0;

          .imageBackground {
            height: 100%;
          }
        }

        .storesWrapper {
          border-right: 1px solid $v2GreenDark;
          padding: 0;

          .store {
            height: auto;
            min-height: 100px;
            flex: 1;

            .info {
              width: 37%;
              padding: 20px;

              .name {
                font-size: 0.95rem;
              }
            }

            .controls {
              width: 63%;

              .map {
                width: 35%;
              }

              .menu {
                width: 65%;
                justify-content: center;
              }
            }

            .comingSoon {
              width: 60%;
              padding: 40px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      .content {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;

        .storeImage,
        .storesWrapper {
          flex: 1;
        }

        .storeImage {
          padding: 0;

          .imageBackground {
            height: 100%;
          }
        }

        .storesWrapper {
          border-right: 1px solid $v2GreenDark;
          padding: 0;

          .store {
            height: auto;
            min-height: 100px;
            flex: 1;

            .info {
              width: 37%;
              padding: 20px;

              .name {
                font-size: 0.95rem;
              }
            }

            .controls {
              width: 63%;

              .map {
                width: 35%;
              }

              .menu {
                width: 65%;
                justify-content: center;
              }
            }

            .comingSoon {
              width: 60%;
              padding: 40px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 390px) {
      .contentWrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: stretch;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;

        .storesWrapper {
          border-right: 1px solid $v2GreenDark;
          padding: 0;

          .store {
            height: auto;
            min-height: 100px;
            flex: 1;

            .info {
              width: 37%;
              padding: 20px;

              .name {
                font-size: 0.95rem;
              }
            }

            .controls {
              width: 63%;

              .map {
                width: 35%;
              }

              .menu {
                width: 55%;
                justify-content: center;
                padding-right: 1.5rem;
              }
            }

            .comingSoon {
              width: 60%;
              padding: 40px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .content {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;

        .storesWrapper {
          .store {
            min-height: 120px;
          }
        }
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .content {
        .storesWrapper {
          .store {
            font-size: 1.1rem;
            line-height: 1.75rem;
            letter-spacing: 0.1rem;
            min-height: 150px;

            .info {
              padding: 30px;

              .name {
                font-size: 1.2rem;
              }

              .type {
                font-size: 0.75rem;
              }
            }

            .comingSoon {
              padding: 45px;
            }
          }
        }
      }
    }
  }

  /* Products */

  .Products {
    background-color: $v2GreenPale;
    color: $v2GreenDark;

    .contentWrapper {
      max-width: 1920px;
      margin: 0 auto;
    }

    .mobileContent {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0 20px;

      .productsAntCollapse {
        .product {
          border-bottom: 1px solid $v2GreenDark;

          .ant-collapse-header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;

            .title {
              @include WorkSansMedium;
              color: $v2GreenDark;
              font-size: 0.95rem;
              text-transform: uppercase;
              letter-spacing: 0.05rem;
              line-height: 1.3rem;
            }

            .panelIcon {
              width: 15px;
              height: auto;
            }

            .panelIcon.rotated {
              transform: scaleY(-1);
            }
          }

          .ant-collapse-content .ant-collapse-content-box {
            padding: 0;
            color: $v2GreenDark;

            .carousel {
              border: 1px solid $v2GreenDark;
              margin-bottom: 30px;

              img {
                width: 100%;
                height: auto;
              }

              .pagination-bullet,
              .pagination-bullet-active {
                display: inline-block;
                border: 1px solid $v2GreenDark;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin: 0 6px;
              }

              .pagination-bullet {
                background-color: $v2GreenPale;
              }

              .pagination-bullet-active {
                background-color: $v2GreenDark;
              }
            }

            .description {
              font-size: 0.95rem;
              line-height: 1.5rem;
              padding-bottom: 10px;
            }

            p,
            ul {
              margin-bottom: 20px;
            }

            li {
              margin-bottom: 20px;

              .disc {
                font-size: 0.75rem;
                margin-right: 12px;
              }
            }
          }
        }

        .product.comingSoon {
          display: flex;
          align-items: center;
          color: $v2GreenDark;
          font-size: 0.95rem;
          line-height: 1.3rem;
          padding: 20px 0;

          .titleText {
            @include WorkSansMedium;
            text-transform: uppercase;
            letter-spacing: 0.05rem;
            margin-right: 10px;
          }

          .comingSoonText {
            text-transform: lowercase;
          }
        }

        .product.noBottomBorder {
          border-bottom: none;
        }
      }

      .productPanelContent {
        .tabList {
          height: 48px;
          display: flex;
          margin-bottom: 20px;

          .tab {
            border: 1px solid $v2GreenDark;
            flex: 1;
            text-transform: uppercase;
          }

          .tab.isActive {
            border: none;

            &:not(:first-of-type) {
              border-left: 1px solid $v2GreenDark;
            }
          }

          .tab:not(:last-of-type) {
            border-right: none;
          }
        }
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    .desktopContent {
      display: none;

      @media screen and (min-width: 900px) {
        display: flex;
        flex-direction: column;

        .productButtons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;

          .button {
            flex: 1;
            border-bottom: 1px solid $v2GreenDark;
            border-right: 1px solid $v2GreenDark;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: $v2GreenDark;
            line-height: 1.3rem;

            .name {
              @include WorkSansMedium;
              text-transform: uppercase;
              font-size: 0.95rem;
              letter-spacing: 0.03rem;
              position: relative;
              padding-bottom: 6px;

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: $v2GreenDark;
                transform-origin: bottom right;
                transition: transform 0.5s ease-out;
              }
            }

            .comingSoon {
              margin-top: 5px;
              text-transform: lowercase;
              font-size: 0.65rem;
              letter-spacing: 0.01rem;
            }

            &:hover {
              background-color: #ffffff;
              color: $v2GreenDark;
            }

            &:hover .name::after {
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }

          .button.selected {
            background-color: $v2GreenDark;
            color: $v2GreenPale;
          }

          .button.isLast {
            border-right: none;
          }

          .button.disabled {
            pointer-events: none;
            cursor: auto;

            &:hover {
              background-color: transparent;
              color: $v2GreenDark;
            }
          }
        }

        .productInfoContent {
          display: flex;

          .info {
            width: calc(
              40% - 1px
            ); // Can't use flex due to image resizing in carousel
            border-left: 1px solid $v2GreenDark;

            .tabList {
              height: 72px;
              display: flex;

              .tab {
                border-bottom: 1px solid $v2GreenDark;
                border-right: 1px solid $v2GreenDark;
                flex: 1;
                
                span {
                  text-transform: uppercase;
                  position: relative;
                  padding-bottom: 6px;

                  &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $v2GreenDark;
                    transform-origin: bottom right;
                    transition: transform 0.5s ease-out;
                  }
                }

                &:hover {
                  background-color: #ffffff;
                  color: $v2GreenDark;
                }

                &:hover span:after {
                  transform: scaleX(1);
                  transform-origin: bottom left;
                }
              }

              .tab.isActive {
                border-bottom-color: transparent;
                pointer-events: none;
              }

              .tab:last-of-type {
                border-right: none;
              }
            }

            .heading {
              height: 80px;
              display: flex;
              align-items: center;
              padding: 20px;
              border-bottom: 1px solid $v2GreenDark;

              h2 {
                color: $v2GreenDark;
              }
            }

            .description {
              padding: 30px;
              color: $v2GreenDark;
              font-size: 18px;
              letter-spacing: 0.03rem;
              line-height: 1.3rem;

              p,
              ul {
                margin-bottom: 20px;
              }

              li {
                margin-bottom: 20px;

                .disc {
                  font-size: 0.75rem;
                  margin-right: 12px;
                }
              }
            }
          }

          .carousel {
            width: 60%; // Can't use flex due to image resizing in carousel

            img {
              width: 100%;
              height: auto;
            }

            .pagination-bullet,
            .pagination-bullet-active {
              display: inline-block;
              border: 1px solid $v2GreenDark;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              margin: 0 6px;
            }

            .pagination-bullet {
              background-color: $v2GreenPale;
            }

            .pagination-bullet-active {
              background-color: $v2GreenDark;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1234px) {
      .contentWrapper {
        padding: 0 60px;
      }

      .desktopContent {
        border-left: 1px solid $v2GreenDark;
        border-right: 1px solid $v2GreenDark;
      }
    }

    @media screen and (min-width: 1366px) {
      .contentWrapper {
        padding: 0 80px;
      }

      .desktopContent {
        .productButtons {
          .button {
            padding: 30px;
            line-height: 1.75rem;

            .name {
              font-size: 1.2rem;
              letter-spacing: 0.1rem;
            }

            .comingSoon {
              font-size: 0.75rem;
            }
          }
        }
        .info {
          .description {
            padding: 40px;
            font-size: 1.1rem;
            line-height: 1.75rem;
            letter-spacing: 0.01rem;

            p,
            ul {
              margin-bottom: 30px;
            }

            li {
              margin-bottom: 30px;

              .disc {
                font-size: 0.95rem;
              }
            }
          }
        }
      }
    }
  }
}
