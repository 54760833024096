.Quiz {
  min-height: 100%; // The "min" here is important.
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // Should be default
  .quizResults {
    display: flex;
    justify-content: center;
    background-color: $whiteBackground;
    img {
      width: 100%;
      max-width: 1224px;
    }
  }
  header {
    width: 100%;
    .contentContainer {
      margin: 0 auto;
      max-width: 1920px;
    }
    .content {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .buttonContainer {
        display: flex;
        width: 50px;
        .closeButton {
          height: 18px;
          border: none;
          padding: 0;
          background-color: transparent;
          img {
            height: 100%;
          }
        }
      }
      .buttonContainer.left {
        justify-content: flex-start;
      }
      .buttonContainer.right {
        justify-content: flex-end;
      }
      .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
          height: 28px;
          img {
            height: 100%;
          }
        }
      }
    }
    @media screen and (min-width: 900px) {
      .content {
        height: 100px;
        padding: 0 30px;
        .buttonContainer {
          .closeButton {
            height: 20px;
          }
        }
      }
    }
    @media screen and (min-width: 1234px) {
      .contentContainer {
        padding: 0 60px;
      }
    }
    @media screen and (min-width: 1366px) {
      .contentContainer {
        padding: 0 80px;
      }
      .content {
        .buttonContainer {
          .closeButton {
            height: 24px;
          }
        }
      }
    }
  }
  header.light {
    border-bottom: $greenDarkAlt2 1px solid;
    @media screen and (min-width: 1234px) {
      .content {
        border-left: 1px solid $greenDarkAlt2;
        border-right: 1px solid $greenDarkAlt2;
      }
    }
  }
  header.dark {
    border-bottom: $whiteBackground 1px solid;
    @media screen and (min-width: 1234px) {
      .content {
        border-left: 1px solid $whiteBackground;
        border-right: 1px solid $whiteBackground;
      }
    }
  }
  .quizViewContainer {
    width: 100%;
    flex-grow: 1; // Fill container...
    flex-shrink: 1; // ... but don't OVERFILL it.
    position: relative; // For absolutely-positioned images
    display: flex;
    flex-direction: column;
    justify-content: flex-start; // Should be default
    .contentContainer, .content {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; // Should be default
    }
    .contentContainer {
      max-width: 1920px;
      margin: 0 auto;
    }
    .completionBarContainer {
      height: 15px;
      width: 100%;
      .completionBar {
        height: 100%;
        background-color: $whiteBackground;
      }
    }
    @media screen and (min-width: 768px) {
      .completionBarContainer {
        height: 20px;
      }
    }
    @media screen and (min-width: 1234px) {
      .contentContainer {
        padding: 0 60px;
      }
      .content.light {
        border-left: 1px solid $greenDarkAlt2;
        border-right: 1px solid $greenDarkAlt2;
      }
      .content.dark {
        border-left: 1px solid $whiteBackground;
        border-right: 1px solid $whiteBackground;
      }
    }
    @media screen and (min-width: 1366px) {
      line-height: 20px; // Overriding global styles
      .contentContainer {
        padding: 0 80px;
      }
    }
    section {
      width: 100%;
      margin: 0;
      padding: 30px;
      flex-grow: 1; // Fill container...
      flex-shrink: 1; // ... but don't OVERFILL it.
      position: relative; // Adjust stacking order
      z-index: 1; // Render above images
      .quizHeading, .quizQuestion, .quizCompletion {
        color: $greenDarkAlt2;
      }
      .quizHeading {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 15px;
        .navControls, .questionIndex {
          @include WorkSansRegular;
          font-size: 12px;
        }
        .navControls {
          width: 75px;
          .backButton {
            background-color: transparent;
            border: none;
          }
        }
        .questionIndex {
          text-transform: uppercase;
          opacity: 1;
          transition: opacity 0.3s linear;
        }
        .questionIndex.invisible {
          opacity: 0;
        }
      }
      .quizQuestion, .quizCompletion {
        @include SimplonMonoRegular;
        text-align: center;
        font-size: 24px;
        line-height: 36px;
        max-width: 375px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .quizQuestion.activity {
        max-width: 100%;
      }
      .answerButtonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 315px;
        margin: 0 auto;
        .answerButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 230px;
          height: 66px;
          padding: 0 10px;
          background-color: $whiteBackground;
          margin-bottom: 20px;
          border: none;
          border-radius: 8px;
          span {
            @include WorkSansRegular;
            color: $greenDarkAlt2;
            font-size: 14px;
            line-height: 18px;
          }
          .topPadding {
            margin-top: 15px;
          }
        }
        .answerButton.selected {
          border: solid $greenDarkAlt2 2px;
        }
        .answerButton.large {
          height: 130px;
        }
      }
      .answerButtonWrapper.grid {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .answerButton {
          width: 47%;
        }
      }
      .answerTextAreaWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        textarea {
          @include WorkSansRegular;
          color: $greenDarkAlt2;
          font-size: 14px;
          line-height: 18px;
          width: 230px;
          height: 200px;
          padding: 20px;
          background-color: $whiteBackground;
          margin-bottom: 30px;
          border: none;
          border-radius: 8px;
        }
        p {
          @include WorkSansRegular;
          color: $greenDarkAlt2;
          font-size: 12px;
        }
      }
      .quizContent {
        margin-bottom: 30px;
        opacity: 1;
        transition: opacity 0.3s linear;
      }
      .quizContent.invisible {
        opacity: 0;
      }
      @media screen and (min-width: 768px) {
        padding: 60px;
        .quizHeading {
          margin: 0 auto;
          margin-bottom: 20px;
          .navControls, .questionIndex {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .quizQuestion, .quizCompletion {
          font-size: 36px;
          line-height: 54px;
          margin-bottom: 60px;
        }
        .quizQuestion {
          max-width: 500px;
        }
        .quizQuestion.barriers {
          max-width: 550px;
        }
        .quizQuestion.activity, .quizQuestion.experience {
          max-width: 980px;
        }
        .quizCompletion {
          max-width: 700px;
        }
        .answerButtonWrapper {
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          max-width: 400px;
          .answerButton {
            width: 175px;
            height: 100px;
            padding: 28px;
            margin-bottom: 30px;
            span {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
        .answerButtonWrapper.grid {
          .answerButton {
            width: 175px;
          }
        }
        .answerTextAreaWrapper {
          textarea {
            font-size: 16px;
            line-height: 20px;
            width: 400px;
            height: 175px;
            padding: 28px;
            margin-bottom: 45px;
          }
          p {
            font-size: 14px;
          }
        }
        .quizContent {
          margin-bottom: 60px;
        }
      }
      @media screen and (min-width: 980px) {
        .answerButtonWrapper {
          max-width: 860px;
        }
      }
    }
    .Question, .Complete {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
    }
    .formWrapper {
      width: 290px;
      margin: 0 auto;
      .formRowWrapper.grid {
        display: flex;
        justify-content: space-between;
        .formRowItem {
          width: 46%;
        }
      }
      select, input {
        @include WorkSansRegular;
        color: $greenDarkAlt2;
        width: 100%;
        height: 30px;
        font-size: 12px;
        line-height: 16px;
        padding: 0 5px;
        border: none;
        border-bottom: solid $greenDarkAlt2 1px;
        border-radius: 0;
        background-color: transparent;
        margin-bottom: 15px;
      }
      input {
        &::placeholder {
          color: $greenDarkAlt2;
          opacity: 1.0;
        }
      }
      select {
        // Custom chevron
        -moz-appearance: none; // Mozilla
        -webkit-appearance: none; // other browsers
        appearance: none; // experimental
        background-image: url('../../assets/images/chevron-down-green.png');
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: top 14px right 5px;
        option {
          color: $brownDarker;
        }
      }
      .formConsent {
        padding-top: 20px;
        margin-bottom: 30px;
        label {
          @include WorkSansRegular;
          color: $greenDarkAlt2;
          font-size: 10px;
          line-height: 14px;
        }
        .ant-checkbox-wrapper {
          align-items: flex-start;
          padding: 0;
        }
        .ant-checkbox-inner {
          background-color: transparent;
          border-color: $greenDarkAlt2;
        }
        .ant-checkbox-checked {
          &::after {
            border-color: $greenDarkAlt2;
          }
          .ant-checkbox-inner {
            background-color: $whiteBackground;
            &::after {
              border-color: $greenDarkAlt2;
            }
          }
        }
      }
      @media screen and (min-width: 768px) {
        width: 540px;
        .formRowWrapper, .formRowWrapper.grid {
          display: flex;
          justify-content: space-between;
          .formRowItem {
            width: 46%;
          }
        }
        select, input {
          height: 50px;
          font-size: 14px;
          line-height: 18px;
        }
        select {
          // Custom chevron
          background-size: 15px;
          background-position: top 24px right 8px;
        }
        .formConsent {
          padding-top: 30px;
          margin-bottom: 45px;
        }
      }
    }
    .navButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .backButton, .nextButton {
        height: 45px;
        border: none;
        padding: 0;
        background-color: transparent;
        img {
          height: 100%;
        }
      }
      .backButton {
        margin-right: 20px;
      }
      @media screen and (min-width: 768px) {
        .backButton {
          margin-right: 30px;
        }
      }
    }
    .infoButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .infoButton {
        height: 45px;
        padding: 0;
        background-color: transparent;
        border: none;
        img {
          height: 100%;
        }
      }
    }
    .submitButtonWrapper, .nextButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .submitButton, .nextButton {
        height: 30px;
        padding: 0 20px;
        letter-spacing: 1px;
        background-color: $whiteBackground;
        border: none;
        @include WorkSansMedium;
        color: $greenDarkAlt2;
        font-size: 10px;
        line-height: 14px;
        border-radius: 15px;
        text-transform: uppercase;
        .logoSpinnerContainer {
          display: inline-block;
          height: 10px;
          margin-right: 5px;
        }
      }
      @media screen and (min-width: 768px) {
        .submitButton {
          height: 40px;
          padding: 0 30px;
          font-size: 12px;
          line-height: 16px;
          border-radius: 20px;
          .logoSpinnerContainer {
            height: 12px;
          }
        }
      }
    }
    .Results {
      max-width: 1366px;
      margin: 0 auto;
      .resultsMessageWrapper {
        padding-bottom: 20px;
        .resultsMessage {
          @include WorkSansRegular;
          color: $whiteBackground;
          font-size: 14px;
          line-height: 20px;
          padding-right: 16px; // Compensating for results text boxes
          padding-left: 16px; // Compensating for results text boxes
          margin-bottom: 15px;
        }
      }
      .resultsDetailsWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-bottom: 1px solid $whiteBackground;
        .resultContent {
          border-right: 1px solid $whiteBackground;
          border-left: 1px solid $whiteBackground;
          .resultTitle {
            display: flex;
            align-items: center;
            padding: 15px;
            border-top: 1px solid $whiteBackground;
            border-bottom: 1px solid $whiteBackground;
            .icon {
              margin-right: 15px;
              img {
                height: 30px;
              }
            }
            .text {
              @include WorkSansRegular;
              color: $whiteBackground;
              font-size: 12px;
              line-height: 18px;
              text-transform: uppercase;
              letter-spacing: 1px;
            }
          }
          .resultText {
            @include SimplonMonoRegular;
            color: $whiteBackground;
            font-size: 12px;
            line-height: 18px;
            padding: 15px;
          }
        }
      }
      @media screen and (min-width: 768px) { 
        .resultsMessageWrapper {
          padding-bottom: 20px;
          .resultsMessage {
            font-size: 16px;
            line-height: 24px;
            padding-right: 21px; // Compensating for results text boxes
            padding-left: 21px; // Compensating for results text boxes
            margin-bottom: 30px;
          }
        }
        .resultsDetailsWrapper {
          .resultContent {
            .resultTitle {
              padding: 20px;
              .icon img {
                height: 40px;
              }
              .text {
                font-size: 14px;
                line-height: 20px;
              }
            }
            .resultText {
              font-size: 14px;
              line-height: 20px;
              padding: 20px;
            }
          }
        }
      }
      @media screen and (min-width: 1024px) {
        .resultsMessageWrapper {
          display: flex;
          padding-bottom: 30px;
          .resultsMessage.first {
            flex: 2;
            margin-right: 40px;
          }
          .resultsMessage.second {
            flex: 1;
          }
        }
        .resultsDetailsWrapper {
          flex-direction: row;
          border-right: 1px solid $whiteBackground;
          .resultContent {
            border-right: none;
            flex: 1;
          }
        }
      }
    }
    // Quiz Images
    .Images {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0; // This should be relative to the quizContainer
      .imageWrapper {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
      }
      // Hide themes by default on mobile
      // (except those listed above)
      .imageWrapper > div {
        display: none;
      }
      @media screen and (min-width: 1024px) {
        .imageWrapper {
          .plant1 {
            display: flex;
            justify-content: space-between;
            .archPlant {
              margin-top: 160px;
              margin-left: 80px;
              height: 300px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .verticalShapes {
              display: initial;
              margin-top: 140px;
              margin-right: 120px;
              height: 300px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .plant2 {
            display: block;
            .vasePlant {
              margin-top: 140px;
              margin-right: 75px;
              text-align: right;
              img {
                height: 320px;
                width: auto;
              }
            }
          }
          .day {
            display: flex;
            justify-content: space-between;
            .sun {
              margin-top: 150px;
              margin-left: 70px;
              height: 220px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .moon {
              margin-top: 390px;
              margin-right: 60px;
              height: 140px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .calendar {
            display: flex;
            justify-content: space-between;
            .shapePlant1 {
              margin-top: 120px;
              margin-left: 30px;
              height: 330px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .potGrid {
              margin-top: 360px;
              margin-right: 50px;
              height: 160px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .time {
            display: flex;
            justify-content: flex-end;
            .clock {
              margin-top: 360px;
              margin-right: 80px;
              height: 140px;
              img {
                height: 100%;
                width: auto;
              }
            }
          }
          .drugs {
            display: flex;
            justify-content: space-between;
            .injection {
              margin-top: 260px;
              margin-left: 70px;
              height: 250px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .bong {
              margin-top: 90px;
              margin-right: 45px;
              height: 300px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .use {
            display: flex;
            justify-content: flex-end;
            .lips {
              margin-top: 320px;
              margin-right: 50px;
              height: 200px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .relaxation {
            display: flex;
            justify-content: space-between;
            .meditation {
              margin-top: 290px;
              margin-left: 45px;
              height: 175px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .reading {
              margin-top: 410px;
              margin-right: 50px;
              height: 130px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .plant3 {
            display: flex;
            justify-content: flex-end;
            .shapePlant2 {
              margin-top: 260px;
              margin-right: 80px;
              height: 280px;
              img {
                height: 100%;
                width: auto;
              }
            }
          }
          .drama {
            display: block;
            .masks {
              margin-top: 320px;
              margin-right: 50px;
              text-align: right;
              img {
                height: 200px;
                width: auto;
              }
            }
          }
          .food {
            display: flex;
            justify-content: space-between;
            .foodDish {
              margin-top: 200px;
              margin-left: 50px;
              height: 230px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .dinerSign {
              margin-top: 80px;
              margin-right: 50px;
              height: 170px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .control {
            display: flex;
            justify-content: flex-end;
            .staircase {
              margin-top: 350px;
              margin-right: 40px;
              height: 175px;
              img {
                height: 100%;
                width: auto;
              }
            }
          }
          .spools {
            display: flex;
            justify-content: space-between;
            .spool1 {
              margin-top: 180px;
              margin-left: 40px;
              height: 160px;
              img {
                height: 100%;
                width: auto;
              }
            }
            .spool2 {
              margin-top: 200px;
              margin-right: 50px;
              height: 200px;
              img {
                height: 100%;
                width: auto;
              }
            } 
          }
          .scents {
            display: flex;
            justify-content: space-between;
            .orange {
              margin-top: 280px;
              margin-left: 75px;
              height: 250px;
              img {
                height: 100%;
                width: auto;
              }
            } 
            .flowers {
              margin-top: 290px;
              margin-right: 60px;
              height: 275px;
              img {
                height: 100%;
                width: auto;
              }
            }
          }
          .drinks {
            display: flex;
            justify-content: flex-end;
            .tea {
              margin-top: 290px;
              margin-right: 40px;
              height: 230px;
              img {
                height: 100%;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

.Quiz.light {
  background-color: $greenLightAlt2;
}

.Quiz.dark {
  background-color: $greenDarkAlt2;
}