@mixin RhodeIslandDeliveryFormButton {
  @include SimplonMonoRegular;
  height: 44px;
  width: 110px;
  border-radius: 22px;
  padding: 0 22px;
  text-transform: uppercase;
  line-height: 1.25rem;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  border: 1px solid;

  .icon {
    font-size: 12px;
    margin-right: 5px;
  }
}

.Delivery {
  background-color: $v2GreenDark;

  @media screen and (min-width: 1234px) {
    padding-bottom: 60px;
  }

  @media screen and (min-width: 1366px) {
    padding-bottom: 80px;
  }

  .sectionTitle {
    @include WorkSansMedium;
    color: $v2GreenPale;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 40px;
    letter-spacing: 1.2px;

    @media screen and (min-width: 1366px) {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 60px;
    }
  }
}

.Intro {
  .pageHeadingContainer, .regCheckContainer {
    max-width: 1920px;
    margin: 0 auto;

    @media screen and (min-width: 1234px) {
      padding: 0 60px;
    }

    @media screen and (min-width: 1366px) {
      padding: 0 80px;
    }
  }

  .pageHeading {
    padding: 120px 20px;
    border-bottom: 1px solid $v2GreenPale;

    h1 {
      @include SimplonMonoRegular;
      font-weight: normal;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      margin: 0 auto;
      color: $v2GreenPale;
      text-transform: none; // override
      letter-spacing: 0; // override
      max-width: 300px;
    }

    @media screen and (min-width: 900px) {
      padding: 160px 20px;

      h1 {
        font-size: 35px;
        line-height: 45px;
        max-width: 500px;
      }
    }

    @media screen and (min-width: 1234px) {
      border-left: 1px solid $v2GreenPale;
      border-right: 1px solid $v2GreenPale;
    }

    @media screen and (min-width: 1366px) {
      padding: 200px 20px;

      h1 {
        font-size: 45px;
        line-height: 60px;
        max-width: 600px;
      }
    }
  }

  .regCheck {
    padding: 40px 20px;
    border-bottom: 1px solid $v2GreenPale;

    .introButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        @include RhodeIslandDeliveryFormButton;
      }

      .introButtonNo {
        border: solid $v2GreenPale 1px;
        background-color: transparent;
        color: $v2GreenPale;
      }

      .introButtonYes {
        border: solid $v2GreenPale 1px;
        background-color: $v2GreenPale;
        color: $v2GreenDark;
        margin-right: 40px;
      }
    }

    @media screen and (min-width: 900px) {
      padding: 60px 20px;

      h1 {
        font-size: 35px;
        line-height: 40px;
        max-width: 500px;
      }
    }

    @media screen and (min-width: 1234px) {
      border-left: 1px solid $v2GreenPale;
      border-right: 1px solid $v2GreenPale;
    }

    @media screen and (min-width: 1366px) {
      padding: 80px 20px;
    }
  }

  
}

.Perks {
  .sectionTitle {
    margin-right: auto;
    margin-left: auto;
    max-width: 380px;

    @media screen and (min-width: 1366px) {
      max-width: 460px;
    }
  }

  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;

    .content {
      padding: 60px 20px;
      border-bottom: 1px solid $v2GreenPale;
    }

    @media screen and (min-width: 1234px) {
      padding: 0 60px;

      .content {
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;
      }
    }

    @media screen and (min-width: 1366px) {
      padding: 0 80px;

      .content {
        padding: 80px 20px;
      }
    }
  }

  .featureContainer {
    display: flex;
    max-width: 380px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 30px 0;

    .feature {
      width: 160px; 
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
      }

      .image.truck img {
        width: 100px;
        height: auto;
      }

      .image.percentile img {
        width: 90px;
        height: auto;
      }

      .text {
        color: $v2GreenPale;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }

    @media screen and (min-width: 900px) {
      padding: 0 0 40px 0;
    }

    @media screen and (min-width: 1366px) {
      max-width: 460px;
      padding-bottom: 60px;

      .feature {
        width: 180px;

        .image {
          height: 120px;
        }

        .image.truck img {
          width: 120px;
        }
  
        .image.percentile img {
          width: 100px;
        }

        .text {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
}

.SignUp {
  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;

    .content {
      padding: 40px 20px;
      border-bottom: 1px solid $v2GreenPale;
    }

    @media screen and (min-width: 900px) {
      .content {
        padding: 60px 20px;
      }
    }

    @media screen and (min-width: 1234px) {
      padding: 0 60px;

      .content {
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;
      }
    }

    @media screen and (min-width: 1366px) {
      padding: 0 80px;

      .content {
        padding: 80px 20px;
      }
    }
  }

  .rhodeIslandDeliveryForm {
    font-size: .95rem;
    line-height: 1.5rem;
    max-width: 500px;
    margin: 0 auto;

    .formItem {
      margin-bottom: 20px;
    }

    .formInputContainer {
      border-bottom: 1px solid $v2GreenPale;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .formInput {
        padding: 10px 0;
        flex-grow: 1;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .formInputButtonWrapper {
        margin-left: 10px;

        button {
          background-color: transparent;
          border: none;
          padding: 3px;
          margin: 0;
          width: 30px;

          img {
            width: 100%;
          }
        }
      }
    }

    .formButtons {
      padding: 30px 0 10px 0;
      display: flex;
      justify-content: center;

      button {
        @include RhodeIslandDeliveryFormButton;
        border: solid $v2GreenPale 1px;
        background-color: $v2GreenPale;
        color: $v2GreenDark;
        width: 125px;

        .logoSpinnerContainer {
          display: inline-block;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    @media screen and (min-width: 900px) {
      max-width: 550px;
      font-size: .85rem;

      .formRow {
        display: flex;
        justify-content: space-between;

        .halfWidthFormItemWrapper {
          width: 46.5%;
        }

        .fullWidthFormItemWrapper {
          width: 100%;
        }
      }

      .formButtons {
        padding: 30px 0 0 0;
      }
    }

    @media screen and (min-width: 1366px) {
      max-width: 600px;
      font-size: .95rem;

      .formButtons {
        padding-top: 40px;
      }
    }
  }
}

.FAQ {  
  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;

    .content {
      padding: 40px 20px;
      border-bottom: 1px solid $v2GreenPale;
    }

    @media screen and (min-width: 900px) {
      .content {
        padding: 60px 20px 40px 20px;
      }
    }

    @media screen and (min-width: 1234px) {
      padding: 0 60px;

      .content {
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;
      }
    }

    @media screen and (min-width: 1366px) {
      padding: 0 80px;

      .content {
        padding: 80px 20px 60px 20px;
      }
    }
  }

  .faqWrapper {
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    .faqItem {
      margin-bottom: 30px;

      .question {
        @include WorkSansMedium;
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        margin-bottom: 20px;
        letter-spacing: 1.2px;
      }

      .answer {
        font-size: .95rem;
        line-height: 1.3rem;
      }

      a {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: 1234px) {
      max-width: 580px;

      .faqItem {
        margin-bottom: 40px;

        .question {
          font-size: 1.1rem;
        }
  
        .answer {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }

    @media screen and (min-width: 1366px) {
      max-width: 660px;

      .faqItem {
        margin-bottom: 50px;

        .question {
          font-size: 1.3rem;
          line-height: 1.75rem;
          margin-bottom: 30px;
        }
  
        .answer {
          font-size: 1.2rem;
          line-height: 1.75rem;
        }
      }
    }
  }
}

.RIFooter {
  .contentContainer {
    max-width: 1920px;
    margin: 0 auto;

    .content {
      padding: 20px;
    }

    @media screen and (min-width: 900px) {
      .content {
        padding: 40px 20px;
      }
    }

    @media screen and (min-width: 1234px) {
      padding: 0 60px;

      .content {
        padding: 60px 20px;
        border-left: 1px solid $v2GreenPale;
        border-right: 1px solid $v2GreenPale;
        border-bottom: 1px solid $v2GreenPale;
      }
    }

    @media screen and (min-width: 1366px) {
      padding: 0 80px;
    }
  }

  p {
    font-size: .65rem;
    line-height: 1.5rem;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: 1234px) {
      font-size: .75rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: 1366px) {
      font-size: .85rem;
    }
  }

}