/* General */
$whiteBackground: #FFFAF5;
$whiteText: #FFFEFD;

/* Brown */
$brownPrimary: #C98463;
$brownDark: #8B533A;
$brownDarkAlt: #8B533A;
$brownDarker: #3C3734;
$brownLight: #AB9085;
$brownPale: #EED5BF;
$brownPaler: #F8F3F0;

/* Green */
$greenPrimary: #80805C;
$greenLight: #AEB8A6;
$greenLightAlt: #ABB5A3;
$greenLightAlt2: #BDC4B6;
$greenDark: #00311D;
$greenDarkAlt: #02423A;
$greenDarkAlt2: #00433A;

/* v2 Redesign */
$v2GreenDark: #00443B; // Primary color
$v2GreenPale: #F6F3EF; // Secondary color; backgrounds

/* Gray */
$gray: #707070;